import React, { useState, useRef, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useDispatchData from "../libs/Getdispaches";
import { Link } from "react-router-dom";
import Spinner from "../../utils/spinner";
import "./super_admin_dispatces.css";

const Superadmindispatches = () => {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useDispatchData();
  const [searchId, setSearchId] = useState("");

  // Flatten pages to get all dispatches
  const allDispatches = data?.pages?.flatMap((page) => page.dispatches) || [];

  // Filter dispatches by search
  const filteredDispatches = allDispatches.filter((dispatch) =>
    dispatch?._id.toLowerCase().includes(searchId.toLowerCase())
  );

  const observerRef = useRef();
  const lastDispatchElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 1.0 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="super_admin_dispatches_container">
      <div className="header">
        <div className="in">
          <p>Dispatch Service</p>
        </div>
        <span>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search Order ID"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />
        </span>
      </div>
      {isError && <p>Error loading dispatch data</p>}
      {isLoading && <Spinner />}
      <div className="dispatch-table">
        {!isLoading && !isError && (
          <table>
            <thead>
              <tr>
                <th>Package ID</th>
                <th>Date Created</th>
                <th>Estimated Delivery Date</th>
                <th>Shipping Cost</th>
                <th>Dispatch Status</th>
                <th>Rider</th>
                <th>Rider Name</th>
                <th>Rider Number</th>
                <th>Rider Branch</th>
                <th>Driver</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredDispatches.map((dispatch, index) => {
                const isLastElement = index === filteredDispatches.length - 1;
                return (
                  <tr
                    key={dispatch?._id}
                    ref={isLastElement ? lastDispatchElementRef : null}
                  >
                    <td>ID {dispatch?._id.toUpperCase().slice(0, 8)}</td>
                    <td>
                      {new Date(
                        dispatch?.packages?.[0]?.dateCreated
                      ).toLocaleDateString()}
                    </td>
                    <td>
                      {new Date(
                        dispatch?.packages?.[0]?.estimated_delivery_date
                      ).toLocaleDateString()}
                    </td>
                    <td>
                      {dispatch?.packages?.[0]?.shipping_cost
                        ? (dispatch?.packages?.[0]?.shipping_cost).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "NGN",
                            }
                          )
                        : ""}
                    </td>
                    <td>
                      {
                        dispatch?.packages[0]?.status[
                          dispatch.packages[0].status.length - 1
                        ]?.status
                      }
                    </td>
                    <td>
                      {dispatch?.packages[0]?.isAssignedToRider
                        ? "Assigned"
                        : "Not Assigned"}
                    </td>
                    <td>
                      {dispatch?.riderDetails
                        ? `${dispatch.riderDetails.first_name}, ${dispatch.riderDetails.last_name}`
                        : "N/A"}
                    </td>
                    <td>{dispatch?.riderDetails?.phone_number || "N/A"}</td>
                    <td>{dispatch?.riderDetails?.branch_full_address || "N/A"}</td>

                    <td>
                      {dispatch?.packages[0]?.isAssignedToDriver
                        ? "Assigned"
                        : "Not Assigned"}
                    </td>
                    <td>
                      <Link to={`/dispatchdetails/${dispatch?._id}`}>
                        Details
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Superadmindispatches;
