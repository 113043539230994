import { useQuery } from 'react-query';

const useSuperTrackPackage = (trackingNumber) => {
  const fetchTrackPackage = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/packages/trackpackage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tracking_number: trackingNumber,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error fetching package tracking information');
    }

    return response.json();
  };

  return useQuery(['trackPackage', trackingNumber], fetchTrackPackage);
};

export default useSuperTrackPackage;
