import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useGetRiders from "../libs/useGetRiders";
import { Fragment, useState, useRef, useCallback, useEffect } from "react";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { GoPlusCircle } from "react-icons/go";
import { CiEdit } from "react-icons/ci";
import useUpdateUserById from "../libs/useUpdateUserById";
import useDeactivateAccount from "../libs/deactivateAccount";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import Spinner from "../../utils/spinner";
import { useNavigate } from "react-router-dom";
import "./superadminrider.css";

function Superadminrider() {
  const {
    data: riders,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetRiders();
  const ridersArray = riders?.pages.flatMap((page) => page.riders) || [];
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRiderId, setSelectedRiderId] = useState(null);
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [unblockOverlay, setUnblockOverlay] = useState(false);
  const [deactivateOverlay, setDeactivateOverlay] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [editOverlay, setEditOverlay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const updateUserHook = useUpdateUserById();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    branch_full_address: "",
    phone_number: "",
    motorcycle_type: "",
    bike_plate_number: "",
    bike_registration_number: "",
    drivers_license_number: "",
  });

  console.log(formData)

  const blockRider = useBlockUserById();
  const unblockRider = useUnblockUserById();
  const deactivateRider = useDeactivateAccount();

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const filteredRiders = ridersArray.filter(
    (rider) =>
      rider.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      rider.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUnblockedUser = async () => {
    try {
      if (!selectedRiderId) return;

      await unblockRider.mutateAsync({ userId: selectedRiderId });
      setUnblockOverlay(false);
      setSelectedRiderId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!selectedRiderId) return;

      await blockRider.mutateAsync({
        userId: selectedRiderId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedRiderId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedRiderId) return;

      await deactivateRider.mutateAsync(selectedRiderId);
      setDeactivateOverlay(false);
      setSelectedRiderId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };

  const observerRef = useRef();
  const lastRiderElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );
  const selectedRider = ridersArray.find(
    (rider) => rider._id === selectedRiderId
  );

  const handleEditClick = () => {
    const rider = ridersArray.find((rider) => rider._id === selectedRiderId);
    if (selectedRider) {
      setFormData({
        first_name: rider.first_name || "",
        last_name: rider.last_name || "",
        email: rider.email || "",
        address: rider.address || "",
        branch_full_address: rider.branch_full_address || "",
        phone_number: rider.phone_number || "",
        motorcycle_type: rider.motorcycle_type || "",
        bike_plate_number: rider.bike_plate_number || "",
        bike_registration_number: rider.bike_registration_number || "",
        drivers_license_number: rider.drivers_license_number || "",
      });
    }
  };

  useEffect(() => {
    if (selectedRider) {
      setFormData({
        first_name: selectedRider.first_name,
        last_name: selectedRider.last_name,
        email: selectedRider.email,
        address: selectedRider.address,
        branch_full_address: selectedRider.branch_full_address,
        phone_number: selectedRider.phone_number,
        motorcycle_type: selectedRider.motorcycle_type,
        bike_plate_number: selectedRider.bike_plate_number,
        bike_registration_number: selectedRider.bike_registration_number,
        drivers_license_number: selectedRider.drivers_license_number,
      });
    }
  }, [selectedRider]);

  const handleCancelEdit = () => {
    setSelectedRiderId(null);
    setEditOverlay(false);
  };

  // const handleUpdateUser = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setIsUpdating(true);
  //     if (!selectedRiderId) return;
  
  //     await updateUserHook.updateUserById(selectedRiderId, formData);
  
  //     setFormData({
  //       first_name: "",
  //       last_name: "",
  //       email: "",
  //       address: "",
  //       branch_full_address: "",
  //       phone_number: "",
  //       motorcycle_type: "",
  //       bike_plate_number: "",
  //       bike_registration_number: "",
  //       drivers_license_number: "",
  //     });
  
  //     setEditOverlay(false);
  //   } catch (error) {
  //     console.error("Failed to update user:", error.message);
  //   } finally {
  //     setIsUpdating(false);
  //   }
  // };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (!selectedRiderId) {
        return;
      }
  
      await updateUserHook.updateUserById(selectedRiderId, {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        address: formData.address,
        branch_full_address: formData.branch_full_address,
        phone_number: formData.phone_number,
        motorcycle_type: formData.motorcycle_type,
        bike_plate_number: formData.bike_plate_number,
        bike_registration_number: formData.bike_registration_number,
        drivers_license_number: formData.drivers_license_number,
      });
  
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update user:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };
  
  

  return (
    <Fragment>
      {editOverlay && (
        <div className="logistic-edits-over-container">
          <form className="card">
            <label>
              First Name
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Last Name
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Home Address
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Branch Full Address
              <input
                type="text"
                name="branch_full_address"
                value={formData.branch_full_address}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Phone Number
              <input
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Motorcycle Type
              <input
                type="text"
                name="motorcycle_type"
                value={formData.motorcycle_type}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Bike Plate Number
              <input
                type="text"
                name="bike_plate_number"
                value={formData.bike_plate_number}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Bike Registration Number
              <input
                type="text"
                name="bike_registration_number"
                value={formData.bike_registration_number}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Driver License Number
              <input
                type="text"
                name="drivers_license_number"
                value={formData.drivers_license_number}
                onChange={handleInputChange}
              />
            </label>
            <div className="btn">
              <button type="button" onClick={handleCancelEdit}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleUpdateUser}
                disabled={isUpdating}
              >
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}
      {blockOverlay && (
        <Fragment>
          <div className="rider-block-over-container">
            <form>
              <p>Are you sure you want to suspend this driver?</p>
              <label>
                Start Date
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label>
                End Date
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
              <div className="btn">
                <button onClick={() => setBlockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleBlockUser}
                  disabled={blockRider.isLoading}
                >
                  {blockRider.isLoading ? "Blocking..." : "Confirm"}
                </button>
              </div>
              {blockRider.isError && (
                <p className="error-message">
                  Error: {blockRider.error.message}
                </p>
              )}
            </form>
          </div>
        </Fragment>
      )}

      {unblockOverlay && (
        <Fragment>
          <div className="rider-unblock-over-container">
            <div className="inner">
              <p>
                Are you sure you want to lift the suspension on this driver?
              </p>
              <div className="btn">
                <button onClick={() => setUnblockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleUnblockedUser}
                  disabled={unblockRider.isLoading}
                >
                  {unblockRider.isLoading ? "Unblocking..." : "Confirm"}
                </button>
              </div>
              {unblockRider.isError && (
                <p className="error-message">
                  Error: {unblockRider.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {deactivateOverlay && (
        <Fragment>
          <div className="rider-delete-over-container">
            <div className="delete-card">
              <TrashIcon />
              <span>Deactivate</span>
              <p>
                Are you sure you want to deactivate this driver? This action
                cannot be undone?
              </p>
              <div className="btn">
                <button onClick={() => setDeactivateOverlay(false)}>
                  Cancel
                </button>
                <button
                  onClick={handleDeactivateAccount}
                  disabled={deactivateRider.isLoading}
                >
                  {deactivateRider.isLoading ? "Deactivating..." : "Confirm"}
                </button>
              </div>
              {deactivateRider.isError && (
                <p className="error-message">
                  Error: {deactivateRider.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      <div className="super_admin_rider">
        <div className="header">
          <p>Rider</p>
          <div className="inner">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search Rider"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
            <button onClick={() => navigate("/Superadminregisterrider")}>
              Register New
            </button>
          </div>
        </div>
        <div className="rider-table">
          {isLoading && <Spinner />}
          {isError && <p>Error loading drivers</p>}
          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>First</th>
                  <th>Last </th>
                  <th>Rider Address</th>
                  <th>Branch</th>
                  <th>Email</th>
                  <th>Phone </th>
                  <th>Cycle Type</th>
                  <th>Cycle Plate No</th>
                  <th>Cycle.Re.NO</th>
                  <th>Driver Licence no</th>
                  <th>Block</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredRiders.map((rider, index) => (
                  <tr
                    key={rider._id}
                    ref={
                      filteredRiders.length === index + 1
                        ? lastRiderElementRef
                        : null
                    }
                  >
                    <td>{rider?.first_name}</td>
                    <td>{rider?.last_name}</td>
                    <td>{clampWords(rider?.address || "N/A", 3)}</td>
                    <td>{rider?.branch_full_address || "N/A"}</td>
                    <td>{rider?.email}</td>
                    <td>{rider?.phone_number || "N/A"}</td>
                    <td>{rider?.motorcycle_type || "N/A"}</td>
                    <td>{rider?.bike_plate_number || "N/A"}</td>
                    <td>{rider?.bike_registration_number || "N/A"}</td>
                    <td>{rider?.drivers_license_number || "N/A"}</td>
                    <td>{rider?.blocked ? "Yes" : "No"}</td>
                    <td>
                      <div className="action">
                        <CiEdit
                          onClick={() => {
                            setEditOverlay(true);
                            setSelectedRiderId(rider._id);
                            handleEditClick();
                          }}
                        />
                        {rider.blocked ? (
                          <GoPlusCircle
                            onClick={() => {
                              setUnblockOverlay(true);
                              setSelectedRiderId(rider._id);
                            }}
                          />
                        ) : (
                          <RemoveIcon
                            onClick={() => {
                              setBlockOverlay(true);
                              setSelectedRiderId(rider._id);
                            }}
                          />
                        )}
                        <TrashIcon
                          onClick={() => {
                            setDeactivateOverlay(true);
                            setSelectedRiderId(rider._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
      </div>
    </Fragment>
  );
}

export default Superadminrider;
