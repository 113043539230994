import "./business-home.css"



const HomeBusiness = () => {
    return ( 
        <div>
            Hello World
        </div>
     );
}
 
export default HomeBusiness;