import { useState, useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';

const useTrackingSupportForm = () => {
  const { token } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [packageInfo, setPackageInfo] = useState(null); 

  const submitTrackingSupport = async (trackingNumber) => {
    setIsLoading(true);
    setPackageInfo(null); 

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/packages/tracking-support`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ trackingNumber }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 404) {
          setError(`Package not found for tracking number: ${trackingNumber}`);
        } else {
          setError(data.message || 'An error occurred.');
        }
        return null;
      }

      setError(null);
      setPackageInfo(data.package); 
      return data.package;
    } catch (error) {
      setError(error.message || 'An error occurred.');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const trackPackage = async (trackingNumber) => {
    setError(null); 
    const result = await submitTrackingSupport(trackingNumber);
    return result;
  };

  return { error, isLoading, trackPackage, packageInfo, setError };
};

export default useTrackingSupportForm;

