import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const fetchCreatorPackages = async ({ pageParam = 1, token }) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/packages/groupedByCreator?page=${pageParam}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch Creator packages");
  }

  return response.json();
};

const useFetchcreatorPackages = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    
    ["creatorPackages"],
    ({ pageParam = 1 }) => fetchCreatorPackages({ pageParam, token }),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalCreators } = lastPage;
        const totalPages = Math.ceil(totalCreators / 10);
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default  useFetchcreatorPackages;
