import React, { useState, useRef, useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { IoArrowBack } from "react-icons/io5";
import useAllPackage from "../libs/getAllPackages";
import Spinner from "../../utils/spinner";
import "./SuperAdminViewAllPackage.css";

const SuperAdminViewAllPackage = () => {
  const navigate = useNavigate();
  const observerRef = useRef();
  const { 
    data, 
    isLoading, 
    isError, 
    isFetchingNextPage, 
    fetchNextPage, 
    hasNextPage, 
  } = useAllPackage();

  const [searchQuery, setSearchQuery] = useState('');

  const filteredPackages = data?.pages?.flatMap(page => 
    page.packages.filter(pkg => 
      pkg.tracking_number.toLowerCase().includes(searchQuery.toLowerCase())
    )
  ) || [];

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  
    return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
  };

  const lastPackageElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
  
      if (observerRef.current) observerRef.current.disconnect();
  
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.5 } 
      );
  
      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );
  

  return (
    <div className='super-admin-viewall-package'>
      <div className="header">
        <div className="back">
          <IoArrowBack onClick={() => navigate('/')} style={{ fontSize: '25px', color: '#284697' }} />
          <p>All Packages</p>
        </div>
        <div className="in">
          <span>
            <SearchIcon />
            <input
              type="search"
              placeholder="Search pkg by Tracking num"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </span>
        </div>
      </div>

      <div className="packages-table">
        {isLoading ? (
           <Spinner />
        ) :isError ? (
          <p style={{fontFamily:'Montserrat'}}>No packages found</p>
        ) : (
        <table>
          <thead>
            <tr>
              <th>Tracking No</th>
              <th>Estimated Delivery Date</th>
              <th>Shipping Cost</th>
              <th>Dispatch Status</th>
              <th>Receiver</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredPackages.map((pkg, index) => {
               const isLastElement = filteredPackages.length === index + 1;
              return (
                <tr 
                  key={pkg.id} 
                  ref={isLastElement ? lastPackageElementRef : null}
                >
                  <td>{pkg.tracking_number}</td>
                  <td>{formatDate(pkg.dateCreated)}</td>
                  <td>
                    {pkg.shipping_cost
                      ? (pkg.shipping_cost / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : ""}
                  </td>
                  <td>{pkg.status[pkg.status.length - 1]?.status}</td>
                  <td>{pkg.receiver?.name}</td>
                  <td><Link to={`/packages/${pkg.id}`}>Details</Link></td>
                </tr>
              );
            })}
          </tbody>
        </table>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default SuperAdminViewAllPackage;
