import "./BusinessNavbar.css";
import React, { useState } from "react";
import { NavLink as Link, Outlet } from "react-router-dom";
import { Fragment } from "react";
import { ReactComponent as MenuIcon } from "../../asset/menu-down.svg";
import { BiCategoryAlt } from "react-icons/bi";
import { CiSettings } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import UserDataFetcher from "../../super_admin/libs/Getadminbyid";

const BusinessNavbar = () => {
  const { adminData } = UserDataFetcher();
  const [dropdowns, setDropdowns] = useState({ management: false, operations: false });

  const toggleDropdown = (key) => {
    setDropdowns((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const NavLinkLCss = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#284697" : "",
      color: isActive ? "#fff" : "",
    };
  };

  return (
    <Fragment>
      <div className="logistic_admin_header">
        <p>
          Welcome back, <span>{adminData?.user?.first_name}</span>{" "}
          <span>{adminData?.user?.last_name}</span>
        </p>
      </div>
      <div className="business-navbar-header">
        <p>Welcome to Business Management</p>
        <div className="menu-icon">
          <MenuIcon />
        </div>
      </div>
      <div className="business-nav-container">
        <div className="links">
          <Link to="/" style={NavLinkLCss}>
            <BiCategoryAlt className="icon" /> Overview
          </Link>
          <div className="dropdown-container">
            <div className="dropdown-button" onClick={() => toggleDropdown("management")}> 
              <span>
                <div className="icon-card">
                  <h2>Management</h2>
                </div>
                {dropdowns.management ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
            {dropdowns.management && (
              <div className="dropdown-content">
                <Link to="/employees" style={NavLinkLCss}>Employees</Link>
                <Link to="/finance" style={NavLinkLCss}>Finance</Link>
              </div>
            )}
          </div>
          <div className="dropdown-container">
            <div className="dropdown-button" onClick={() => toggleDropdown("operations")}> 
              <span>
                <div className="icon-card">
                  <h2>Operations</h2>
                </div>
                {dropdowns.operations ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
            {dropdowns.operations && (
              <div className="dropdown-content">
                <Link to="/logistics" style={NavLinkLCss}>Logistics</Link>
                <Link to="/inventory" style={NavLinkLCss}>Inventory</Link>
              </div>
            )}
          </div>
          <Link to="/BusinessSettings" style={NavLinkLCss}>
            <CiSettings className="icon" /> Settings
          </Link>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default BusinessNavbar;