import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const getUndeliveredPackages = async ({ pageParam = 1, token, user }) => {
  if (!user) throw new Error("User is not authenticated");

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/packages/undelivered/${user.userId}?page=${pageParam}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch undelivered packages");
  }

  return response.json();
};

const useGetUndeliveredPackages = () => {
  const { token, user } = useContext(AuthContext);

  return useInfiniteQuery(
    ["adminundeliveredPackages", user?.userId], 
    ({ pageParam = 1 }) => getUndeliveredPackages({ pageParam, token, user }),
    {
      enabled: !!user,
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPackages } = lastPage;
        const totalPages = Math.ceil(totalPackages / 10);
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useGetUndeliveredPackages;
