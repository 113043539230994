import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const fetchUndeliveredPackages = async ({ pageParam = 1, token }) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/packages/undelivered?page=${pageParam}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch undelivered packages");
  }

  return response.json();
};

const useFetchUndeliveredPackages = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ["undeliveredPackages"],
    ({ pageParam = 1 }) => fetchUndeliveredPackages({ pageParam, token }),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalPackages } = lastPage;
        const totalPages = Math.ceil(totalPackages / 10);
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useFetchUndeliveredPackages;
