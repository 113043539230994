import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../Context/AuthContext";

const acceptPackageByRider = async (packageId, token, user) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/packages/acceptpackage-rider/${packageId}/${user.userId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to accept package");
    }

    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

const useAcceptPackageByRider = () => {
  const { token, user } = useContext(AuthContext); 
  const queryClient = useQueryClient();

  return useMutation(
    (packageId) => acceptPackageByRider(packageId, token, user), 
    {
      onSuccess: () => {
        queryClient.invalidateQueries("riderAssignment");
      },
    }
  );
};


export default useAcceptPackageByRider;
