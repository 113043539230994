import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const fetchLogisticsOfficers = async ({ pageParam = 1 }, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/users/logisticsmanagers?page=${pageParam}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch logistics officers');
  }

  return response.json();
};

const useFetchLogisticsOfficers = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ['logisticsOfficers'],
    ({ pageParam = 1 }) => fetchLogisticsOfficers({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalLogisticsManagers } = lastPage;
        const totalPages = Math.ceil(totalLogisticsManagers / 10);
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useFetchLogisticsOfficers;







