import { useQuery } from "react-query";

const fetchBusinessRoles = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/business/getbusinesses`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch business roles");
  }

  return response.json();
};

export const useGetBusinessRoles = () => {
  return useQuery("businessRoles", fetchBusinessRoles);
};

export default fetchBusinessRoles;
