import useTrackingSupportForm from "../../libs/fetchPackageBySupport";
import { ReactComponent as SearchIcon } from "../../Assets/iconoir_search.svg";
import { ReactComponent as TrackingIcon } from "../../Assets/Ellipse 3681.svg";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CiCircleRemove } from "react-icons/ci";
import { useState } from "react";
import Spinner from "../../Spinner/Spinner";
import "./TrackPackage.css";

const TrackPackage = () => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const { error, trackPackage, packageInfo, isLoading, setError } =
    useTrackingSupportForm();

  const handleTrackingSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    trackPackage(trackingNumber);
  };

  return (
    <div className="track-package-container">
      <div className="header">
        <p>Track Packages</p>
        <form onSubmit={handleTrackingSubmit}>
          <button type="submit">
            <SearchIcon style={{ width: "18px", height: "18px" }} />
          </button>
          <input
            type="text"
            name="tracking_number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            placeholder="Enter tracking number"
            required
          />
        </form>
      </div>
      <div className="wrapper">
        <div
          style={{ color: "red", margin: " auto", fontFamily: "Montserrat" }}
        >
          {isLoading && <Spinner />}
        </div>
        {error && (
          <div
            style={{ color: "red", margin: " auto", fontFamily: "Montserrat" }}
          >
            <p>{typeof error === "string" ? error : "An error occurred."}</p>
          </div>
        )}
        {packageInfo && (
          <>
            <span>
              <h3>ID {packageInfo._id}</h3>
              <p> {new Date(packageInfo?.dateCreated).toLocaleString()}</p>
            </span>
            <div className="sender-card">
              <span>
                {" "}
                <h3>SENDER'S INFO</h3>
              </span>
              <div className="sender-card-box">
                <div className="package-span">
                  <span>
                    <p>Sender's Full Name</p>
                    <h3> {packageInfo.sender.name}</h3>
                  </span>
                  <span>
                    <p>Home Address</p>
                    <h3>{packageInfo.sender.address_1}</h3>
                  </span>
                  <span>
                    <p>State</p>
                    <h3>{packageInfo.sender.state}</h3>
                  </span>
                </div>
                <div className="package-span">
                  <span>
                    <p>Other Name</p>
                    <h3> {packageInfo.sender.contact_name}</h3>
                  </span>
                  <span>
                    <p>Other Address</p>
                    <h3>{packageInfo.sender.address_2}</h3>
                  </span>
                  <span>
                    <p>Zip Code</p>
                    <h3>{packageInfo.sender.zip_code}</h3>
                  </span>
                </div>
                <div className="package-span">
                  <span>
                    <p>Email</p>
                    <h3>{packageInfo.sender.email}</h3>
                  </span>
                  <span>
                    <p>Phone Number</p>
                    <h3>{packageInfo.sender.phone_number}</h3>
                  </span>
                </div>
              </div>
            </div>
            <div className="receiver-card">
              <span>
                <h3>RECEIVER'S INFO</h3>
              </span>
              <div className="receiver-card-box">
                <div className="package-span">
                  <span>
                    <p>Receiver's Full Name</p>
                    <h3>{packageInfo.receiver.name}</h3>
                  </span>
                  <span>
                    <p>Home Address</p>
                    <h3>{packageInfo.receiver.address_1}</h3>
                  </span>
                  <span>
                    <p>State</p>
                    <h3>{packageInfo.receiver.state}</h3>
                  </span>
                </div>
                <div className="package-span">
                  <span>
                    <p>Other Name</p>
                    <h3>{packageInfo.receiver.contact_name}</h3>
                  </span>
                  <span>
                    <p>Other Address</p>
                    <h3>{packageInfo.receiver.address_2}</h3>
                  </span>
                  <span>
                    <p>Zip Code</p>
                    <h3>{packageInfo.receiver.zip_code}</h3>
                  </span>
                </div>
                <div className="package-span">
                  <span>
                    <p>Email</p>
                    <h3>{packageInfo.receiver.email}</h3>
                  </span>
                  <span>
                    <p>Phone Number</p>
                    <h3>{packageInfo.receiver.phone_number}</h3>
                  </span>
                </div>
              </div>
            </div>
            <div className="package-info-card">
              <span>
                <h3>PACKAGE INFO</h3>
              </span>
              <div className="package-info-box">
                <div className="package-span">
                  <span>
                    <p>Assigned to Driver</p>
                    <h3>{packageInfo.isAssignedToDriver ? "Yes" : "No"}</h3>
                  </span>
                  <span>
                    <p>Assigned to Rider</p>
                    <h3>{packageInfo.isAssignedToRider ? "Yes" : "No"}</h3>
                  </span>

                  <span>
                    <p>Name</p>
                    <h3>{packageInfo.name}</h3>
                  </span>
                  <span>
                    <p>Weight</p>
                    <h3>{packageInfo.weight}</h3>
                  </span>
                  <span>
                    <p>Quantity</p>
                    <h3>{packageInfo.quantity}</h3>
                  </span>
                  <span>
                    <p>Branch Address</p>
                    <h3>{packageInfo.creator.branch_full_address}</h3>
                  </span>
                </div>
                <div className="package-span">
                  <span>
                    <p>Shipping Cost</p>
                    <h3>
                    {packageInfo?.shipping_cost
                    ? (packageInfo.shipping_cost).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "NGN",
                        }
                      )
                    : ""}
                    </h3>
                  </span>
                  <span>
                    <p>Value</p>
                    <h3>
                    {packageInfo?.items_value
                    ? (packageInfo.items_value).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "NGN",
                        }
                      )
                    : ""}
                    </h3>
                  </span>
                  <span>
                    <p>Delivery Method</p>
                    <h3>{packageInfo.delivery_method}</h3>
                  </span>
                  <span>
                    <p>Payment Method</p>
                    <h3>{packageInfo.payment_method}</h3>
                  </span>
                </div>
                <div className="package-span">
                  <span>
                    <p>Original Shipping Cost</p>
                    <h3>{packageInfo.original_shipping_cos}</h3>
                  </span>
                  <span>
                    <p>Total Discount</p>
                    <h3>{packageInfo.total_discount}</h3>
                  </span>
                  <span>
                    <p>Discount %</p>
                    <h3>{packageInfo.discount_percentage}</h3>
                  </span>
                  <span>
                    <p>Same day Delivery</p>
                    <h3>{packageInfo.isEmergency ? "Yes" : "No"}</h3>
                  </span>
                  <span>
                    <p>Pick Up</p>
                    <h3>{packageInfo.isPickupRequired ? "Yes" : "No"}</h3>
                  </span>
                </div>
                <div className="package-span">
                  <span>
                    <p>Tracking Number</p>
                    <h3>{packageInfo.tracking_number}</h3>
                  </span>
                  <span>
                    <p>Way Bill Number</p>
                    <h3>{packageInfo.waybill.waybillNumber}</h3>
                  </span>
                  <span>
                    <p>Bar Code</p>
                    <img
                      src={`data:image/png;base64,${packageInfo.waybill.barcode}`}
                      alt="Barcode"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="status-history">
              <h3>Status History</h3>
              <ul className="info">
                {packageInfo.status.map((statusItem) => (
                  <li key={statusItem._id}>
                    <p>
                      {statusItem.status === "Delivered" ? (
                        <IoIosCheckmarkCircleOutline className="delivered-icon" />
                      ) : statusItem.status === "Delivery Failed" ? (
                        <CiCircleRemove className="delivery-failed-icon" />
                      ) : (
                        <TrackingIcon className="tracking-icon" />
                      )}
                      {statusItem.status}
                    </p>

                    <div className="wrapper">
                      {statusItem.city && statusItem.state && (
                        <p>
                          Location: {statusItem.city}, {statusItem.state}
                        </p>
                      )}
                      {statusItem.createdAt && (
                        <p>
                          {new Date(statusItem.createdAt).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            }
                          )}
                        </p>
                      )}
                      {statusItem.receivedBy && (
                        <h3>Received By: {statusItem.receivedBy}</h3>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TrackPackage;
