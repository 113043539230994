import React, { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useGetCurrentUser } from "../libs/fetchCurrentUser";
import { useGetBusinessRoles } from "../libs/businessRoles";


const SECRET_KEY = process.env.REACT_APP_ENCRIPTION_KEY;

export const AuthContext = createContext({
  isLoggedIn: false,
  businessRoles: null,
  user: null,
  token: null,
  Login: () => {},
  Logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [businessRoles, setBusinessRoles] = useState(null); 

  const { data: currentUserData, isLoading: isCurrentUserLoading } =
    useGetCurrentUser(token);
    const { data: businessRolesData, isLoading: isBusinessRolesLoading } =
    useGetBusinessRoles(); 

  // Encrypt token
  const encryptToken = (token) => {
    return CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
  };

  // Decrypt token
  const decryptToken = (encryptedToken) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Error decrypting token:", error);
      return null;
    }
  };

  const Login = useCallback((user) => {
    const encryptedToken = encryptToken(user.token);
    setIsLoggedIn(true);
    setUser(user);
    setToken(user.token);
    Cookies.set("authToken", encryptedToken, {
      expires: 10 / 24, 
      secure: true, 
      sameSite: "Strict", 
    });
  }, []);

  useEffect(() => {
    const encryptedAuthToken = Cookies.get("authToken");

    if (encryptedAuthToken) {
      const decryptedToken = decryptToken(encryptedAuthToken);
      if (decryptedToken) {
        setToken(decryptedToken);
        setIsLoggedIn(true);
      } else {
        console.error("Failed to decrypt token. Logging out.");
        setIsLoggedIn(false);
        setUser(null);
        setToken(null);
        Cookies.remove("authToken");
      }
    } else {
      setIsLoggedIn(false);
      setUser(null);
      setToken(null);
      Cookies.remove("authToken");
    }
  }, [navigate]);

  useEffect(() => {
    if (!isCurrentUserLoading && currentUserData) {
      setUser(currentUserData);
    }
  }, [isCurrentUserLoading, currentUserData]);

  useEffect(() => {
    if (!isBusinessRolesLoading && businessRolesData) {
      setBusinessRoles(businessRolesData);
    }
  }, [isBusinessRolesLoading, businessRolesData]);

  const Logout = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Logout failed on the server.");
      }

      console.log("User logged out successfully on the server.");
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setIsLoggedIn(false);
      setUser(null);
      setToken(null);
      setBusinessRoles(null); 
      Cookies.remove("authToken");
      navigate("/Login");
    }
  }, [navigate, token]);

  const value = {
    isLoggedIn,
    user,
    token,
    Login,
    businessRoles, 
    Logout,
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};