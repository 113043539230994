import { Fragment, useState, useRef, useCallback, useEffect } from "react";
import useGetSupport from "../libs/useGetSupport";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { GoPlusCircle } from "react-icons/go";
import { CiEdit } from "react-icons/ci";
import useDeactivateAccount from "../libs/deactivateAccount";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import useUpdateUserById from "../libs/useUpdateUserById";
import Spinner from "../../utils/spinner";
import { useNavigate } from "react-router-dom";
import "./Superadminsupport.css";

function Superadminsupport() {
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetSupport();
  const SupportArray = data?.pages.flatMap(page => page.supports) || [];

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSupportsId, setSelectedSupportsId] = useState(null);
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [unblockOverlay, setUnblockOverlay] = useState(false);
  const [deactivateOverlay, setDeactivateOverlay] = useState(false);
   const [editOverlay, setEditOverlay] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
   const [selectedUserId, setSelectedUserId] = useState(null);

   const updateUserHook = useUpdateUserById();
   const [formData, setFormData] = useState({
     first_name: '',
     last_name: '',
     email: '',
     address:'',
     phone_number: '',
   });

 
   const handleInputChange = (e) => {
     setFormData({
       ...formData,
       [e.target.name]: e.target.value,
     });
   };

   const selectedOfficer = SupportArray.find(
    (support) => support._id === selectedUserId
  );

  const handleEditClick = () => {
    setFormData({
      first_name: selectedOfficer?.first_name || '',
      last_name: selectedOfficer?.last_name || '',
      email: selectedOfficer?.email || '',
      address: selectedOfficer?.address || '',
      phone_number: selectedOfficer?.phone_number || '',
    });
    setEditOverlay(true);
  };

    useEffect(() => {
      if (selectedUserId !== null) {
        setFormData({
          first_name: selectedOfficer?.first_name || '',
          last_name: selectedOfficer?.last_name || '',
          email: selectedOfficer?.email || '',
          address: selectedOfficer?.address || '',
          phone_number: selectedOfficer?.phone_number || '',
        });
      } else {
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          address: '',
        });
      }
    }, [selectedUserId, selectedOfficer]);
  
    const handleCancelEdit = () => {
      setSelectedUserId(null);
      setEditOverlay(false);
    };

  
    const handleUpdateUser = async (e) => {
      e.preventDefault();
      try {
        setIsUpdating(true);
        if (!selectedUserId) {
          return;
        }
  
        await updateUserHook.updateUserById(selectedUserId, {
          userId: selectedUserId,
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          address: formData.address,
          phone_number: formData.phone_number,
        });
  
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          address: ''
        });
        setEditOverlay(false);
      } catch (error) {
        console.error("Failed to update user:", error.message);
      } finally {
        setIsUpdating(false);
      }
    };



  const blockSupports = useBlockUserById();
  const unblockSupports = useUnblockUserById();
  const deactivateSupports = useDeactivateAccount();

  const filteredSupports = SupportArray.filter(
    (support) =>
      support.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      support.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleBlockUser = async () => {
    try {
      if (!selectedSupportsId) {
        console.error("User ID not available.");
        return;
      }

      await blockSupports.mutateAsync({
        userId: selectedSupportsId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedSupportsId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleUnblockedUser = async () => {
    try {
      if (!selectedSupportsId) {
        console.error("User ID not available.");
        return;
      }

      await unblockSupports.mutateAsync({ userId: selectedSupportsId });
      setUnblockOverlay(false);
      setSelectedSupportsId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedSupportsId) {
        console.error("User ID not available.");
        return;
      }

      await deactivateSupports.mutateAsync(selectedSupportsId);
      setDeactivateOverlay(false);
      setSelectedSupportsId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  return (
    <Fragment>
      {
        editOverlay && (
          <div className="support-edits-over-container">
            <form className="card">
              <label>
                First Name
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Last Name
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Home address
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </label>
              <label>
              Phone Number
                <input
                  type="tel"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                />
              </label>
              <div className="btn">
                <button onClick={handleCancelEdit}>Cancel</button>
                <button onClick={handleUpdateUser}>
                  {isUpdating ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        )
      }
      {blockOverlay && (
        <Fragment>
          <div className="support-block-over-container">
            <form>
              <p>Are you sure you want to suspend this Customer Care Support?</p>
              <label>
                Start Date
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label>
                End Date
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
              <div className="btn">
                <button onClick={() => setBlockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleBlockUser}
                  disabled={blockSupports.isLoading}
                >
                  {blockSupports.isLoading ? "Blocking..." : "Confirm"}
                </button>
              </div>
              {blockSupports.isError && (
                <p className="error-message">
                  Error: {blockSupports.error.message}
                </p>
              )}
            </form>
          </div>
        </Fragment>
      )}

      {unblockOverlay && (
        <Fragment>
          <div className="support-unblock-over-container">
            <div className="inner">
              <p>
                Are you sure you want to lift the suspension on this Customer Care Support?
              </p>
              <div className="btn">
                <button onClick={() => setUnblockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleUnblockedUser}
                  disabled={unblockSupports.isLoading}
                >
                  {unblockSupports.isLoading ? "Unblocking..." : "Confirm"}
                </button>
              </div>
              {unblockSupports.isError && (
                <p className="error-message">
                  Error: {unblockSupports.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {deactivateOverlay && (
        <Fragment>
          <div className="support-delete-over-container">
            <div className="delete-card">
              <TrashIcon />
              <span>Deactivate</span>
              <p>
                Are you sure you want to deactivate this Customer Care Support? This action
                cannot be undone?
              </p>
              <div className="btn">
                <button onClick={() => setDeactivateOverlay(false)}>
                  Cancel
                </button>
                <button
                  onClick={handleDeactivateAccount}
                  disabled={deactivateSupports.isLoading}
                >
                  {deactivateSupports.isLoading ? "Deactivating..." : "Confirm"}
                </button>
              </div>
              {deactivateSupports.isError && (
                <p className="error-message">
                  Error: {deactivateSupports.error.message}
                </p>
              )}
            </div>
          </div>
        </Fragment>
      )}

      <div className="super_admin_market-container">
        <div className="header">
          <p>Support</p>
          <div className="inner">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
            <button onClick={() => navigate("/Supperadminregistersupport")}>
              Register New
            </button>
          </div>
        </div>

        <div className="market-place-table">
          {isLoading && (
            <Spinner />
          )}

          {isError && (
            <p style={{fontFamily: 'Montserrat'}}>Error loading data</p>
          )}

          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Address</th>
                  <th>Email address</th>
                  <th>Phone Number</th>
                  <th>Block</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredSupports.map((support, index) => {
                  const isLastElement = index === filteredSupports.length - 1;
                  return (
                    <tr
                      key={support._id}
                      ref={isLastElement ? lastProductElementRef : null}
                    >
                      <td>{support.first_name}</td>
                      <td>{support.last_name}</td>
                      <td>{support.address || "N/A"}</td>
                      <td>{support.email}</td>
                      <td>{support.phone_number || "N/A"}</td>
                      <td>{support.blocked ? "Yes" : "No"}</td>
                      <td>
                        <div className="action">
                        <CiEdit onClick={() => {
                          setSelectedUserId(support._id);
                          handleEditClick(); 
                        }} />
                          {support.blocked ? (
                            <GoPlusCircle
                              onClick={() => {
                                setUnblockOverlay(true);
                                setSelectedSupportsId(support._id);
                              }}
                            />
                          ) : (
                            <RemoveIcon
                              onClick={() => {
                                setBlockOverlay(true);
                                setSelectedSupportsId(support._id);
                              }}
                            />
                          )}
                          <TrashIcon
                            onClick={() => {
                              setDeactivateOverlay(true);
                              setSelectedSupportsId(support._id);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
           )}
         </div>
           {isFetchingNextPage && <Spinner />}
       </div>
     </Fragment>
   );
 }
 
 export default Superadminsupport;



