import React, {useState, useRef, useCallback } from "react";
  import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
  import useFetchUndeliveredPackages from "../libs/fetchUndeliveredPackages";
  import Spinner from "../../utils/spinner";
  import "./Undelivered_Packages.css";
  
  export default function Superadminundeliveredpackages() {
    const [searchTerm, setSearchTerm] = useState("");
  
    const {
      data,
      isLoading,
      isError,
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage,
    } = useFetchUndeliveredPackages();
  
    const undeliveredArray =
      data?.pages?.flatMap((page) => page.packages) || [];
  
    const filteredUndelivered = undeliveredArray.filter((pkg) =>
      pkg.tracking_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pkg.sender.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pkg.receiver.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const observerRef = useRef();
    const lastUndeliveredElementRef = useCallback(
      (node) => {
        if (isFetchingNextPage) return;
        if (observerRef.current) observerRef.current.disconnect();
  
        observerRef.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        });
  
        if (node) observerRef.current.observe(node);
      },
      [isFetchingNextPage, fetchNextPage, hasNextPage]
    );
  
    return (
      <div className="super_admin_undelivered_container">
        <div className="header">
          <p>Undelivered Packages</p>
          <span>
            <SearchIcon />
            <input
              type="search"
              placeholder="Search by Tracking Number, Sender or Receiver Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </span>
        </div>
        <div className="undelivered-packages-table">
          {isLoading && <Spinner />}
          {isError && <p style={{fontFamily: 'Montserrat'}}>No undelivered packages available now</p>}
          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>Tracking </th>
                  <th>Sender </th>
                  <th> Number</th>
                  <th> State</th>
                  <th> City</th>
                  <th> Email</th>
                  <th>Receiver </th>
                  <th> Number</th>
                  <th> State</th>
                  <th> City</th>
                  <th>Date Created</th>
                  <th>Est. Delivery Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredUndelivered.map((undeliver, index) => {
                  const isLastElement = filteredUndelivered.length === index + 1;
                  return (
                    <tr
                      key={undeliver._id}
                      ref={isLastElement ? lastUndeliveredElementRef : null}
                    >
                      <td>{undeliver.tracking_number}</td>
                      <td>{undeliver.sender.name}</td>
                      <td>{undeliver.sender.phone_number}</td>
                      <td>{undeliver.sender.state}</td>
                      <td>{undeliver.sender.city}</td>
                      <td>{undeliver.sender.email}</td>
                      <td>{undeliver.receiver.name}</td>
                      <td>{undeliver.receiver.phone_number}</td>
                      <td>{undeliver.receiver.state}</td>
                      <td>{undeliver.receiver.city}</td>
                      <td>{new Date(undeliver.dateCreated).toLocaleDateString()}</td>
                      <td>{new Date(undeliver.estimated_delivery_date).toLocaleDateString()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
      </div>
    );
  }
  