// import React, { useState } from 'react';
// import Spinner from '../../Spinner/Spinner';
// import { useNavigate } from 'react-router-dom';
// import { LiaEyeSlashSolid, LiaEyeSolid } from "react-icons/lia";
// import "./Signup.css";

// const defaultForm = {
//     first_name: "",
//     last_name: "",
//     email: "",
//     phone_number: "",
//     password: "",
//     country_code: "+234"
// };

// const Signup = () => {
//     const [formInput, setFormInput] = useState(defaultForm);
//     const [error, setError] = useState(null);
//     const [showPass, setShowPass] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const { first_name, last_name, email, phone_number, password } = formInput;

//     const navigate = useNavigate();

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormInput({ ...formInput, [name]: value });
//         setError(null);
//     };

//     const handleShowPass = () => {
//         setShowPass(!showPass);
//     };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setIsLoading(true);
    //     setError(null);

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/customer`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(formInput),
    //         });

    //         const resData = await response.json();

    //         if (!response.ok) {
    //             throw new Error(resData.message);
    //         }

    //         localStorage.setItem('email', email);

    //         setFormInput(defaultForm);
    //         setIsLoading(false);
    //         navigate(`/EmailVerificationForm?email=${encodeURIComponent(email)}`);
    //     } catch (error) {
    //         setIsLoading(false);
    //         setError(error.message);
    //         console.log(formInput);
    //     }
    // };

//     return (
//         <div className="sign-up-form">
//             <h3>Create Account</h3>
//             <form onSubmit={handleSubmit}>
//                 <div className="input-combination-1">
//                     <span>
//                         <label>First Name</label>
//                         <input type="text" name="first_name" value={first_name} onChange={handleChange} required />
//                     </span>
//                     <span>
//                         <label>Last Name</label>
//                         <input type="text" name="last_name" value={last_name} onChange={handleChange} required />
//                     </span>
//                 </div>
//                 <span>
//                     <label>Email Address</label>
//                     <input type="email" name="email" value={email} onChange={handleChange} required />
//                 </span>
//                 <span>
//                     <label>Phone Number</label>
//                     <div className="input-combination-2">
//                         <div className="zip-code">+234</div>
//                         <input type="tel" name="phone_number" value={phone_number} onChange={handleChange} required />
//                     </div>
//                 </span>
//                 <span>
//                     <label>Password</label>
//                     <div className="pass">
//                         <input type={showPass ? "text" : "password"} name="password" value={password} onChange={handleChange} required />
//                         <div onClick={handleShowPass}>
//                             {showPass ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
//                         </div>
//                     </div>
//                 </span>
//                 {error && <p style={{ color: 'red', textAlign: "center" }}>{error}</p>}
//                 <button type="submit" disabled={isLoading}>
//                     {isLoading ? <Spinner /> : "Sign Up"}
//                 </button>
//                 <p onClick={() => navigate('/LogIn')}>Already have an account? <span>Log in</span></p>
//             </form>
//         </div>
//     );
// }

// export default Signup;




import React, { useState } from 'react';
import Spinner from '../../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { LiaEyeSlashSolid, LiaEyeSolid } from "react-icons/lia";
import "./Signup.css";

const defaultForm = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    country_code: "+234"
};

const businessDefaultForm = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    country_code: "+234",
    business_email: "",
    business_password: "",
    confirm_business_password: ""
};

const Signup = () => {
    const [formInput, setFormInput] = useState(defaultForm);
    const [businessFormInput, setBusinessFormInput] = useState(businessDefaultForm);
    const [error, setError] = useState(null);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { first_name, last_name, email, phone_number, password } = formInput;
    const { full_name, business_email, business_password, confirm_business_password } = businessFormInput;
    const [customer, setCustomer] = useState(true);
    const [business, setBusiness] = useState(false);
    const [selected, setSelected] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInput({ ...formInput, [name]: value });
        setError(null);
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formInput),
            });

            const resData = await response.json();

            if (!response.ok) {
                throw new Error(resData.message);
            }

            localStorage.setItem('email', email);

            setFormInput(defaultForm);
            setIsLoading(false);
            navigate(`/EmailVerificationForm?email=${encodeURIComponent(email)}`);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
            console.log(formInput);
        }
    };


    const handleBusinessChange = (e) => {
        const { name, value } = e.target;
        setBusinessFormInput({ ...businessFormInput, [name]: value });
        setError(null);
    };

    const handleShowPass = () => setShowPass(!showPass);
    const handleShowConfirmPass = () => setShowConfirmPass(!showConfirmPass);

    const handleCustomer = () => {
        setCustomer(true);
        setBusiness(false);
    };

    const handleBusiness = () => {
        setCustomer(false);
        setBusiness(true);
    };

    const handleCustomerClick = () => {
        setSelected("customer");
        handleCustomer(); 
      };
    
      const handleBusinessClick = () => {
        setSelected("business");
        handleBusiness();
      };

    const validateBusinessForm = () => {
        if (!full_name || !business_email || !business_password || !confirm_business_password) {
            setError("All fields are required.");
            return false;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(business_email)) {
            setError("Invalid email format.");
            return false;
        }

        if (business_password.length < 8) {
            setError("Password must be at least 8 characters.");
            return false;
        }

        if (business_password !== confirm_business_password) {
            setError("Passwords do not match.");
            return false;
        }

        return true;
    };

    const handleBusinessSubmit = (e) => {
        e.preventDefault();

        if (!validateBusinessForm()) return;

        sessionStorage.setItem('businessDetails', JSON.stringify(businessFormInput));
        navigate('/businessdetails');
    };

    return (
        <div className="sign-up-mother">
            <div className="card-1">
                <div className="login-image-overlay">
                    <p>Empowering your business with seamless last-mile solutions.</p>
                </div>
            </div>
            <div className="card-2">
                <h3>Register With Us!</h3>
               
                <div className="switch">
          <button
            onClick={handleCustomerClick}
            className={selected === "customer" ? "active-btn" : ""}
          >
            Customer
          </button>
          <button
            onClick={handleBusinessClick}
            className={selected === "business" ? "active-btn" : ""}
          >
            Business
          </button>
        </div>

                {customer && (
                    <div className="sign-up-form">
                        <h3>Create Customer Account</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="input-combination-1">
                                <span>
                                    <label>First Name</label>
                                    <input type="text" name="first_name" value={first_name} onChange={handleChange} required />
                                </span>
                                <span>
                                    <label>Last Name</label>
                                    <input type="text" name="last_name" value={last_name} onChange={handleChange} required />
                                </span>
                            </div>
                            <div className="input-combination-1">
                                <span>
                                    <label>Email Address</label>
                                    <input type="email" name="email" value={email} onChange={handleChange} required />
                                </span>
                                <span>
                                    <label>Phone Number</label>
                                    <div className="input-combination-2">
                                        <div className="zip-code">+234</div>
                                        <input type="tel" name="phone_number" value={phone_number} onChange={handleChange} required />
                                    </div>
                                </span>
                            </div>
                            <span>
                                <label>Password</label>
                                <div className="pass">
                                    <input type={showPass ? "text" : "password"} name="password" value={password} onChange={handleChange} required />
                                    <div onClick={handleShowPass}>
                                        {showPass ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
                                    </div>
                                </div>
                            </span>
                            {error && <p style={{ color: 'red', textAlign: "center" }}>{error}</p>}
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? <Spinner /> : "Sign Up"}
                            </button>
                            <p onClick={() => navigate('/LogIn')}>Already have an account? <span>Log in</span></p>
                        </form>
                    </div>
                )}

                {business && (
                    <div className="sign-up-form">
                        <h3>Create Business Account</h3>
                        <form onSubmit={handleBusinessSubmit}>
                            <div className="input-combination-1">
                                <span>
                                    <label>Full Name</label>
                                    <input type="text" name="full_name" value={full_name} onChange={handleBusinessChange}  />
                                </span>
                                <span>
                                    <label>Email Address</label>
                                    <input type="email" name="business_email" value={business_email} onChange={handleBusinessChange}  />
                                </span>
                            </div>
                            <div className="input-combination-1">
                                <span>
                                    <label>Password</label>
                                    <div className="pass">
                                        <input type={showPass ? "text" : "password"} name="business_password" value={business_password} onChange={handleBusinessChange}  />
                                        <div onClick={handleShowPass}>
                                            {showPass ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
                                        </div>
                                    </div>
                                </span>
                                <span>
                                    <label>Confirm Password</label>
                                    <div className="pass">
                                        <input type={showConfirmPass ? "text" : "password"} name="confirm_business_password" value={confirm_business_password} onChange={handleBusinessChange}  />
                                        <div onClick={handleShowConfirmPass}>
                                            {showConfirmPass ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
                                        </div>
                                    </div>
                                </span>
                            </div>

                            {error && <p style={{ color: 'red', textAlign: "center" }}>{error}</p>}
                            <button type="submit" disabled={isLoading}>
                                Proceed
                            </button>
                            <p onClick={() => navigate('/LogIn')}>Already have an account? <span>Log in</span></p>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Signup;
