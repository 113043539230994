import React, { useState, useCallback, useRef, useEffect,  Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { CiEdit } from "react-icons/ci";
import { GoPlusCircle } from "react-icons/go";
import Spinner from "../../utils/spinner";
import useFetchFreightOfficers from "../libs/fetchFreightOfficer";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import useDeactivateAccount from "../libs/deactivateAccount";
import useUpdateFreightById from "../libs/useUpdateFreightById";
import "./SuperAdmin_freight.css";

const SuperAdminFreight = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = useFetchFreightOfficers();
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [unblockOverlay, setUnblockedOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);

  const updateFreightOfficer = useUpdateFreightById();
  const blockUser = useBlockUserById();
  const unblockUser = useUnblockUserById();
  const deactivateAccount = useDeactivateAccount();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    country_code: "",
    state: "",
  });

  const observerRef = useRef();

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const selectedOfficer = data?.freightManagers?.find(
    (officer) => officer._id === selectedUserId
  );
  
  const filteredOfficers = data?.pages
    ?.flatMap((page) => page.freightManagers)
    ?.filter((officer) => officer._id.toLowerCase().includes(searchTerm.toLowerCase()));

  const lastOfficerRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );


  useEffect(() => {
    if (!isLoading && selectedUserId) {
      const allFreightManagers = data?.pages.flatMap(page => page.freightManagers) || [];
      
      const selectedOfficer = allFreightManagers.find(
        (officer) => officer._id === selectedUserId
      );
      
      if (selectedOfficer) {
        setFormData({
          first_name: selectedOfficer.first_name || "",
          last_name: selectedOfficer.last_name || "",
          email: selectedOfficer.email || "",
          address: selectedOfficer.address || "",
          phone_number: selectedOfficer.phone_number || "",
          country_code: selectedOfficer.country_code || "",
          state: selectedOfficer.state || "",
        });
      } else {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          address: "",
          phone_number: "",
          country_code: "",
          state: "",
        });
      }
    }
  }, [selectedUserId, isLoading, data]);
  
  
  const handleCancelEdit = () => {
    setSelectedUserId(null);
    setEditOverlay(false);
  };


  const handleEditClick = (officer) => {
    if (officer) {
      setSelectedUserId(officer._id);
      setFormData({
        first_name: officer.first_name || "",
        last_name: officer.last_name || "",
        email: officer.email || "",
        phone_number: officer.phone_number || "",
        address: officer.address || "",
        country_code: officer.country_code || "",
        state: officer.state || "",
      });
      setEditOverlay(true);
    }
  };
  

  const handleUpdateFreightOfficer = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (selectedUserId) {
        await updateFreightOfficer.updateFreightById(selectedUserId, formData);
      }
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update freight officer:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };


const handleBlockUser = async () => {
  try {
    if (!selectedUserId) {
      console.error("User ID not available.");
      return;
    }

    await blockUser.mutateAsync({
      userId: selectedUserId,
      blockData: { startDate, endDate },
    });
    setBlockOverlay(false);
    setSelectedUserId(null);
  } catch (error) {
    console.error("Failed to block user:", error.message);
  }
};

const handleUnblockedUser = async () => {
  try {
    if (!selectedUserId) {
      console.error("User ID not available.");
      return;
    }

    await unblockUser.mutateAsync({ userId: selectedUserId });
    setUnblockedOverlay(false);
    setSelectedUserId(null);
  } catch (error) {
    console.error("Failed to unblock user:", error.message);
  }
};

const handleDeactivateAccount = async () => {
  try {
    if (!selectedUserId) {
      console.error("User ID not available.");
      return;
    }

    await deactivateAccount.mutateAsync(selectedUserId);
    setDeleteOverlay(false);
    setSelectedUserId(null);
  } catch (error) {
    console.error("Failed to deactivate account:", error.message);
  }
};


  return (
    <Fragment>
      {
          editOverlay && (
            <div className="freight-edits-over-container">
              <form className="card">
                <label>
                  First Name
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Last Name
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Email
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Home address
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Phone Number
                  <input
                    type="tel"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Country Code
                  <input
                    type="tel"
                    name="country_code"
                    value={formData.country_code}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                State
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </label>
                <div className="btn">
                  <button onClick={handleCancelEdit}>Cancel</button>
                  <button onClick={handleUpdateFreightOfficer}>
                    {isUpdating ? "Updating..." : "Update"}
                  </button>
                </div>
              </form>
            </div>
          )}

{deleteOverlay && (
          <div className="freight-delete-over-container">
            <div className="delete-card">
              <CautionIcon />
              <span>Delete</span>
              <p>
                Are you sure you want to delete this account? This action cannot
                be undone?
              </p>
              <div className="btn">
                <button onClick={() => setDeleteOverlay(false)}>Cancel</button>
                <button onClick={handleDeactivateAccount}>
                  {deactivateAccount.isLoading
                    ? "Deactivating..."
                    : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        )}
        {unblockOverlay && (
          <div className="freight-unblock-over-container">
            <div className="inner">
              <p>Are you sure you want to lift the suspension on this user?</p>
              <div className="btn">
                <button onClick={() => setUnblockedOverlay(false)}>No</button>
                <button
                  type="button"
                  disabled={unblockUser.isLoading}
                  onClick={handleUnblockedUser}
                >
                  {unblockUser.isLoading ? "Unblocking..." : "Confirm"}
                </button>
              </div>
              {unblockUser.isError && (
                <p className="error-message">
                  Error: {unblockUser.error.message}
                </p>
              )}
            </div>
          </div>
        )}
        {blockOverlay && (
          <div className="freight-block-over-container">
            <form>
              <p>Are you sure you want to suspend this officer?</p>
              <label>
                Start Date
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label>
                End Date
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
              <div className="btn">
                <button onClick={() => setBlockOverlay(false)}>Cancel</button>
                <button
                  type="button"
                  onClick={handleBlockUser}
                  disabled={blockUser.isLoading}
                >
                  {blockUser.isLoading ? "Blocking..." : "Confirm"}
                </button>
              </div>
              {blockUser.isError && (
                <p className="error-message">Error: {blockUser.error.message}</p>
              )}
            </form>
          </div>
        )}

      <div className="super_admin_freight_container">
        <div className="header">
          <p>Freight Admin Officer</p>
          <div className="in">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search Charterer Officer by ID"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
            <button onClick={() => navigate("/createfreightofficer")}>Register New</button>
          </div>
        </div>
        <div className="admin-freight-table">
          {isLoading ? (
          <Spinner/>
          ) : isError ? (
            <p>Error fetching freight officers.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Branch Name</th>
                  <th>Phone</th>
                  <th>Blocked</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredOfficers?.length > 0 ? (
                  filteredOfficers.map((officer, index) => {
                    const isLastElement = index === filteredOfficers.length - 1;
                    return (
                      <tr key={officer._id} ref={isLastElement ? lastOfficerRef : null}>
                        <td>{officer._id.slice(0, 5).toUpperCase()}</td>
                        <td>{officer.first_name}</td>
                        <td>{officer.last_name}</td>
                        <td>{officer.email}</td>
                        <td>{officer.address}</td>
                        <td>{officer.phone_number}</td>
                        <td>{officer.blocked ? "Blocked" : "Active"}</td>
                        <td>
                          <div className="action">
                            <CiEdit onClick={() => handleEditClick(officer)} />
                            {officer?.blocked ? (
                              <GoPlusCircle
                                onClick={() => {
                                  setUnblockedOverlay(true);
                                  setSelectedUserId(officer._id);
                                }}
                              />
                            ) : (
                              <RemoveIcon
                                onClick={() => {
                                  setBlockOverlay(true);
                                  setSelectedUserId(officer._id);
                                }}
                              />
                            )}
                            <TrashIcon
                              onClick={() => {
                                setDeleteOverlay(true);
                                setSelectedUserId(officer._id);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8">No Freight Officers Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
      </div>
    </Fragment>
  );
};

export default SuperAdminFreight;



