import "./Logisticsnavbar.css";
import React, {useState, useEffect} from "react";
import { NavLink as Link, Outlet, useLocation } from "react-router-dom";
import { Fragment } from "react";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1.svg";
import { BiCategoryAlt } from "react-icons/bi";
import { ReactComponent as BpersonIcon } from "../../asset/bi_person.svg";
import { ReactComponent as MenuIcon } from "../../asset/menu-down.svg";
import UserDataFetcher from "../../super_admin/libs/Getadminbyid";
import { GoPackage } from "react-icons/go";
import { IoBicycleSharp } from "react-icons/io5";
import { IoCreateOutline } from "react-icons/io5";
import { RiShareForwardBoxFill } from "react-icons/ri";
import { GiShoppingCart } from "react-icons/gi";
import { TbCubeSend } from "react-icons/tb";
import { PiBusLight } from "react-icons/pi";
import { LuBus } from "react-icons/lu";
import { CiSettings } from "react-icons/ci";
import { BiChat } from 'react-icons/bi';
import { FaMotorcycle } from "react-icons/fa6";
import { TfiPackage } from "react-icons/tfi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { TbAffiliate } from "react-icons/tb";

const LogistcsNavbar = () => {
  const location = useLocation();
  const { adminData } = UserDataFetcher();
   const [dropdowns, setDropdowns] = useState({
      admins: false,
      costManagement: false,
      packagesManagement: false,
      others: false,
    });

    useEffect(() => {
      const path = location.pathname;
      setDropdowns({
        admins: path.includes("Logisticsdriverrider") ,
        packagesManagement: path.includes("Logisticactivepkg") || path.includes("Logissticsacivedps") || path.includes("Logisticcreatecategory") || path.includes("/Logisticsender") || path.includes("Logisticspackages")  || path.includes("packagereceipt") || path.includes("logisticundeliveredpackages") || path.includes("Logissticspickups") || path.includes("Logisticstrips") || path.includes("Logisticsinterstatetrips") || path.includes("Logisticsortcenter"),
        others: path.includes("LogisticBroadcastMsg") 
      });
    }, [location]);
  
    const toggleDropdown = (key) => {
      setDropdowns((prev) => {
        const newState = Object.keys(prev).reduce((acc, curr) => {
          acc[curr] = curr === key ? !prev[curr] : false;
          return acc;
        }, {});
        return newState;
      });
    };

    useEffect(() => {
      const button = document.querySelector('.admins-button');
    
      if (button) { 
        const toggleActive = () => {
          button.classList.toggle('active');
        };
    
        button.addEventListener('click', toggleActive);
    
        return () => {
          button.removeEventListener('click', toggleActive);
        };
      }
    }, []);
    

  const NavLinkLCss = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#284697" : "",
      color: isActive ? "#fff" : "",
    };
  };
  return (
    <Fragment>
      <div className="logistic_admin_header">
        <p>
          Welcome back, <span>{adminData?.user?.first_name}</span>{" "}
          <span>{adminData?.user?.last_name}</span>
        </p>
        <div className="side">
          <BpersonIcon className="round" />
          <MenuIcon />
        </div>
      </div>
      <div className="logistic-nav-container">
        <FrameIcon />
        <div className="links">
          <Link to="/" style={NavLinkLCss}>
            <BiCategoryAlt className="icon" /> Overview
          </Link>
         <div className="admins-button" onClick={() => toggleDropdown("packagesManagement")}>
          <span>
            <div className="icon-card">
              <TfiPackage style={{ fontSize: "26px" , color:'#284697'}} />
              <h2>Pkgs Management</h2>
            </div>
           {dropdowns.packagesManagement ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </span>
          {
            dropdowns.packagesManagement && (
              <div className="admins-drop">
                 <Link to="/Logisticactivepkg" style={NavLinkLCss}>
          <GoPackage className="icon"/> Active Packages 
          </Link>
          <Link to="/Logissticsacivedps" style={NavLinkLCss}>
          <GoPackage className="icon"/> Active Dispatch 
          </Link>
          <Link to="/Logisticcreatecategory" style={NavLinkLCss}>
          <IoCreateOutline className="icon" /> Create Category
          </Link>
          <Link to="/Logisticsender" style={NavLinkLCss}>
          <RiShareForwardBoxFill className="icon" /> Create Packages
          </Link>
          <Link to="/Logisticspackages" style={NavLinkLCss}>
          <GiShoppingCart className="icon" /> Packages
          </Link>
          <Link to="/packagereceipt" style={NavLinkLCss}>
          <GiShoppingCart className="icon" /> Packages Receipts
          </Link>
          <Link to="/logisticundeliveredpackages" style={NavLinkLCss}>
          <GoPackage className="icon"/> Undelivered Packages
          </Link>
          <Link to="/Logissticspickups" style={NavLinkLCss}>
          <TbCubeSend className="icon" /> Pickups
          </Link>
          <Link to="/Logisticstrips" style={NavLinkLCss}>
          <PiBusLight className="icon" />Local Trips
          </Link>
          <Link to="/Logisticsinterstatetrips" style={NavLinkLCss}>
          <LuBus className="icon" /> Interstate Trips
          </Link>
          <Link to="/Logisticsortcenter" style={NavLinkLCss}>
          <LuBus className="icon" /> Sort Center Arrival
          </Link>
              </div>
            )}
         </div>
         <div className="admins-button" onClick={() => toggleDropdown("admins")}>
          <span>
            <div className="icon-card">
            <FaMotorcycle  style={{ fontSize: "26px", color:'#284697' }} />
              <h2>Admins</h2>
            </div>
          {dropdowns.admins ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </span>
          {
            dropdowns.admins && (
              <div className="admins-drop">
              <Link to="/Logisticsdriverrider" style={NavLinkLCss}>
          <IoBicycleSharp className="icon" />  Riders
          </Link>
              </div>
            )}
         </div>
         <div className="admins-button" onClick={() => toggleDropdown("others")}>
          <span>
            <div className="icon-card">
            <TbAffiliate style={{ fontSize: "26px", color:'#284697' }}  />
              <h2>Others</h2>
            </div>
          {dropdowns.others ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </span>
          {
            dropdowns.others && (
              <div className="admins-drop">
               <Link to='/LogisticBroadcastMsg' style={NavLinkLCss}>
          <BiChat style={{fontSize:'24px'}} />
          Broadcast Message
          </Link>
         
              </div>
            )}
         </div>

         
          <Link to="/Lgisticsettings" style={NavLinkLCss}>
          <CiSettings  className="icon"/> Settings
          </Link>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default LogistcsNavbar;
