import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import "./Business_details.css";

export default function Businessdetails() {
    const navigate = useNavigate();
    
    // Load stored business data from sessionStorage
    const storedData = JSON.parse(sessionStorage.getItem('businessDetails')) || {};
    
    const [formInput, setFormInput] = useState({
        business_name: '',
        business_email_address: storedData.business_email || '',
        business_phone_number: '',
        business_address: '',
        state: '',
        business_type: ''
    });

    const [error, setError] = useState(null);
      const [isLoading, setIsLoading] = useState(false);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInput({ ...formInput, [name]: value });
        setError(null);
    };

    const validateForm = () => {
        const { business_name, business_email_address, business_phone_number, business_address, state, business_type } = formInput;
        if (!business_name || !business_email_address || !business_phone_number || !business_address || !state || !business_type) {
            setError("All fields are required.");
            return false;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(business_email_address)) {
            setError("Invalid email format.");
            return false;
        }

        if (business_phone_number.length < 10) {
            setError("Invalid phone number.");
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        if (!validateForm()) return;

        console.log("Submitted Data:", formInput);
       
    };

    return (
        <div className='business-details'>
            <div className="card-1">
            <div className="login-image-overlay">
                    <p>Empowering your business with seamless last-mile solutions.</p>
                </div>
            </div>
            <div className="card-2">
            <span>
                <FaArrowLeft onClick={() => navigate("/SignUp")} className='icon' />
                <h3>Back</h3>
            </span>
            <p>Enter your business details</p>
            <form onSubmit={handleSubmit}>
                <div className="input-combination">
                <span>
               <label>Business Name</label>
               <input type="text" name="business_name" value={formInput.business_name} onChange={handleChange} required />
               </span>

              <span>
              <label>Business Email Address</label>
              <input type="email" name="business_email_address" value={formInput.business_email_address} onChange={handleChange} required />
              </span>
                </div>

                <div className='input-combination'>
                    
               <span>
               <label>Business Phone Number</label>
               <input type="tel" name="business_phone_number" value={formInput.business_phone_number} onChange={handleChange} required />
               </span>

               <span>
               <label>Business Address</label>
               <input type="text" name="business_address" value={formInput.business_address} onChange={handleChange} required />
               </span>
                </div>

                <div className='input-combination'>
                <span>
               <label>State</label>
               <input type="text" name="state" value={formInput.state} onChange={handleChange} required />
               </span>

             <span>
             <label>Business Type</label>
             <input type="text" name="business_type" value={formInput.business_type} onChange={handleChange} required />
             </span>
                </div>

                <button type="submit" disabled={isLoading}>
                {isLoading ? "Registering..." : "Register"}
                    </button>
                {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
            </form>
            </div>
        </div>
    );
}
