import usePackageDetails from "../libs/fetchPackageDetails";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import "./Superadminpackagedeatails.css";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

function Superadminpackagedeatails() {
  const { data, isLoading, isError } = usePackageDetails();
  const navigate = useNavigate();
  const packageDetails = data?.package;

  return (
    <div className="pkg-details-container">
      <div className="header">
        <IoMdArrowBack onClick={() => navigate("/")} className="icon" />
        <h1>Package Details</h1>
      </div>
      {isLoading && <Spinner />}
      {isError && <p style={{fontFamily: 'Montserrat', marginTop: '10px'}}>No package details available now!</p>}
      <div className="card">
        {!isLoading && !isError && (
          <>
            <div className="header">
              <p>ID : {packageDetails._id?.toUpperCase().slice(0, 8)}</p>
              <p>{formatDate(packageDetails?.dateCreated)}</p>
            </div>

            <div className="sender">
              <p>SENDER'S INFO</p>
            </div>
            <div className="sender-info">
              <div className="card">
                <p>Senders Full name</p>
                <span>{packageDetails?.sender?.name}</span>
              </div>
              <div className="card">
                <p>Email</p>
                <span>{packageDetails?.sender?.email}</span>
              </div>
              <div className="card">
                <p>Phone Number</p>
                <span>{packageDetails?.sender?.phone_number}</span>
              </div>
              <div className="card">
                <p>Home Adress</p>
                <span>{packageDetails?.sender?.address_1}</span>
              </div>
              <div className="card">
                <p>Other Adress</p>
                <span>{packageDetails?.sender?.address_2}</span>
              </div>
              <div className="card">
                <p>State</p>
                <span>{packageDetails?.sender?.state}</span>
              </div>
              <div className="card">
                <p>Zip Code</p>
                <span>{packageDetails?.sender?.zip_code}</span>
              </div>
            </div>
            <div className="sender">
              <p>RECEIVER’S INFO</p>
            </div>
            <div className="sender-info">
              <div className="card">
                <p>Senders Full name</p>
                <span>{packageDetails?.receiver?.name}</span>
              </div>
              <div className="card">
                <p>Email</p>
                <span>{packageDetails?.receiver?.email}</span>
              </div>
              <div className="card">
                <p>Phone Number</p>
                <span>{packageDetails?.receiver?.phone_number}</span>
              </div>
              <div className="card">
                <p>Home Adress</p>
                <span>{packageDetails?.receiver?.address_1}</span>
              </div>
              <div className="card">
                <p>Other Adress</p>
                <span>{packageDetails?.receiver?.address_2}</span>
              </div>
              <div className="card">
                <p>State</p>
                <span>{packageDetails?.receiver?.state}</span>
              </div>
              <div className="card">
                <p>Zip Code</p>
                <span>{packageDetails?.receiver?.zip_code}</span>
              </div>
            </div>
            <div className="sender">
              <p>ITEM(S) INFO</p>
            </div>
            <div className="sender-info">
              <div className="card">
                <p>Item</p>
                <span>{packageDetails?.name}</span>
              </div>
              <div className="card">
                <p>Weight</p>
                <span>{packageDetails?.weight}</span>
              </div>
              <div className="card">
                <p>Quantity</p>
                <span>{packageDetails?.quantity}</span>
              </div>
              <div className="card">
                <p>Payment Method</p>
                <span>{packageDetails?.payment_method}</span>
              </div>
              <div className="card">
                <p>Est. Delivery date</p>
                <span>
                  {formatDate(packageDetails?.estimated_delivery_date)}
                </span>
              </div>
              <div className="card">
                <p>Valued at</p>
                <span>
                  {packageDetails?.items_value
                    ? (packageDetails.items_value).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "NGN",
                        }
                      )
                    : ""}
                </span>
              </div>
              <div className="card">
                <p>Shipping Fee</p>
                <span>
                  {packageDetails?.shipping_cost
                    ? (packageDetails.shipping_cost).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "NGN",
                        }
                      )
                    : ""}
                </span>
              </div>
              <div className="card">
                <p>Delivery Method</p>
                <span>{packageDetails?.delivery_method}</span>
              </div>
              <div className="card">
                <p>Driver</p>
                <span>{packageDetails?.isAssignedToDriver}</span>
              </div>
              <div className="card">
                <p>Rider</p>
                <span>{packageDetails?.isAssignedToRider}</span>
              </div>
              <div className="card">
                <p>Waybill Number</p>
                <span>{packageDetails?.waybill?.waybillNumber}</span>
              </div>
              <div className="card">
                <p>Barcode</p>
                <img
                  src={`data:image/png;base64, ${packageDetails?.waybill?.barcode}`}
                  alt="Barcode"
                />
              </div>
            </div>
            
          </>
        )}
      </div>
    </div>
  );
}

export default Superadminpackagedeatails;
