import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Fragment } from "react";
import Navbar from "./Navigation/Navbar";
import About from "./Routes/About/About";
import Contact from "./Routes/Contact/Contact"
import Blog from "./Routes/Blog/Blog"
import Market from "./Routes/Marketplace/Market";
import Home from "./Routes/Home/Home";
import Signup from "./Auth/Signup/Signup";
import Login from "./Auth/Login/Login";
import Businessdetails from "./Auth/BusinessDetails/businessdetails";
import Footer from "./Footer/Footer";
import ForgetPass from "./Auth/Forget/ForgetPass";
import ResetPassword from "./Auth/Reset/ResetPass";
import FaqComponent from "./Faq/FaqComponent";
import Sender from "./Routes/Ship/Sender";
import PackageReceiver from "./Routes/Ship/PackageRceiver";
import PackageCategory from "./Routes/Ship/PackageCategory";
import Summary from "./Routes/Ship/summary";
import Getquote from "./Routes/Getquote/Getquote";
import MerchantForm from "./Routes/Marketplace/Merchant/MerchantForm";
import ShippingDetail from "./Routes/Marketplace/Cart/CartPayment/ShippingDetail";
import SingleBlog from "./Routes/Blog/BlogDetail";
import CustomerChangePass from "./Routes/Marketplace/MarketUser/CustomerChangePass";
import EmailVerificationForm from "./Auth/Token/EmailVerification";
import Onetimeverificationcode from "./Auth/Token/onetimeverificationcode";
import OtpRequest from "./Auth/Token/OtpRequest";
import SuscriberForm from "./Routes/Company_Bond/suscriberform";
import { AuthContext } from "./Context/AuthContext";
import { useContext } from "react";
import OverView from "./Support/SupportOverView/OverView";
import ScrollToTop from "./Footer/ScrollToTop";
import NotFound from "./Routes/NotFound/notfound";






import SupportDash from "./Support/SupportNav/SupportDash";
import SupportMessage from "./Support/PublicMessage/SupportMessage";
import TrackPackage from "./Support/TrackPackage/TrackPackage";
import AdminChat from "./Support/AdminChat/AdminChat"
import SupportSetting from "./Support/SupportSetting/SupportSetting";
import SupportChangePass from "./Support/SupportSetting/SupportPassword/SupportChangePass";




import Superadminoverview from "./super_admin/super_admin-overview/Superadminoverview";
import Superadmindispatches from "./super_admin/super_admin_dispatches/Superadmindispatches";
import Superadminpackagedeatails from "./super_admin/super_admin_packagedetails/Superadminpackagedeatails";
import SuperAdminDispatchDetails from "./super_admin/super_admin_dispatchdetails/super_admin_dispachdetails";
import Superadminlogistic from "./super_admin/super_admin_logistic/superadminlogistic";
import Superamdindriver from "./super_admin/super_admin_driver/super_amdin_driver";
import Superadminresgisterlogistics from "./super_admin/super_admin_register_logistics/superadminresgisterlogistics";
import Superadminregisterdriver from "./super_admin/super_admin_register_driver/Superadminregisterdriver";
import Superadminrider from "./super_admin/super_admin_rider/Superadminrider";
import Superadminregisterrider from "./super_admin/super_admin_register_rider/Superadminregisterrider";
import Superadminmrarketmanager from "./super_admin/super_admin_m_place/Superadminm-manager";
import Superregisstermarkeplace from "./super_admin/super_admin_register_market_manager/Superregisstermarkeplace";
import Superadminsupport from "./super_admin/super_admin_support/Superadminsupport";
import Supperadminregistersupport from "./super_admin/super_admin_register_ssupport/Supperadminregistersupport";
import Superadminblog from "./super_admin/super_admin_blog/Superadminblog";
import Superadmincreateblog from "./super_admin/super_admin_create_blogs/Superadmincreateblog";
import Superadminchat from "./super_admin/super_admin_chat/Superadminchat";
import Superadminbroadcast from "./super_admin/super_admin_brocasst/Superadminbroadcast";
import Superadminsetting from "./super_admin/super_admin-settings/Superadminsetting";
import Superadminchangepass from "./super_admin/super_admin_changepass/Superadminchangepass";
import SuperNavbar from "./super_admin/super_admin_navbar/Supernavabr";
import SuperadminToken from "./super_admin/Super_admin_token/superadmintoken";
import SuperadminCharterer from "./super_admin/super_admin_charterer/superadmincharterer";
import SuperAdminFreight from "./super_admin/super_admin_freight/superadminfreight";
import Createchartererofficer from "./super_admin/super_admin_create_charterer/createchartererofficer";
import Createfreightofficer from "./super_admin/super_admin_create_freight/createfreightofficer";
import Superadminviewallpackage from "./super_admin/super_admin_viewAll_package/superadminviewallpackage";
import Superadminshippingcost from "./super_admin/super_admin_shipping_cost/superadminshippingcost";
import Superadmincreateshipcost from "./super_admin/super_admin_create_cost/superadmincreateshipcost";
import Superadmingetquote from "./super_admin/supera_admin_getquote/superadmingetquote";
import Superadmincreateinvestor from "./super_admin/super_admin_create_investor/superadmincreateinvestor";
import Superadmininvestor from "./super_admin/super_admin_investors/superadmininvestor";
import Ontripdriver from "./super_admin/super_admin_ontrip-driver/ontripdriver";
import Localshippingcost from "./super_admin/super_admin_local_shipping_cost/localshippingcost";
import Createlocalshippingcost from "./super_admin/super_admin_create_local_shipping_cost/createlocalshippingcost";
import AllUsers from "./super_admin/super_admin_all_users/allusers";
import Superadminundeliveredpackages from "./super_admin/super_admin_undelivered_package/superadminundeliveredpackages";
import Superadminallbranchpackages from "./super_admin/super_admin_all_branch_packages/superadminallbranchpackages";
import Superadmingetquotelocalcost from "./super_admin/super_admin_getquote_localcost/superadmingetquotelocalcost";



import Charteringservice from "./Routes/Chartering/charteringservice";
import Carcharterservice from "./Routes/Chartering/AllCharter/carcharterservice";
import Jeepcharterservice from "./Routes/Chartering/AllCharter/jeepcharterservice";
import Siennacharterservice from "./Routes/Chartering/AllCharter/siennacharterservice";
import Buscharterservice from "./Routes/Chartering/AllCharter/buscharterservice";
import Executivecharter from "./Routes/Chartering/AllCharter/executivecharter";
import Charterdetail from "./Routes/Chartering/CharterDetails/charterdetail";
import Passengerdetails from "./Routes/Chartering/Passenger/passengerdetails";
import Charterdash from "./Routes/Chartering/CharterAdmin/CharterDash/charterdash";
import Charters from "./Routes/Chartering/CharterAdmin/Charter/charters";
import Vehicle from "./Routes/Chartering/CharterAdmin/Vehicle/vehicle";
import Setting from "./Routes/Chartering/CharterAdmin/Setting/setting";
import Addvehicle from "./Routes/Chartering/CharterAdmin/Vehicle/addvehicle";
import Vehicledetail from "./Routes/Chartering/CharterAdmin/Vehicle/VehicleDetail/vehicledetail";
import Charterpassword from "./Routes/Chartering/CharterAdmin/Setting/charterpassword";
import Startedcharter from "./Routes/Chartering/CharterAdmin/Charter/startedcharter";
import Completedcharter from "./Routes/Chartering/CharterAdmin/Charter/completedcharter";
import Bookrequest from "./Routes/Chartering/CharterAdmin/Charter/bookrequest";
import Order from "./Routes/Chartering/CharterAdmin/Charter/order";
import Overview from "./Routes/Chartering/CharterAdmin/Charter/overview";


import Truckselection from "./Routes/Freight/truckselection";
import Neworderfreight from "./Routes/Freight/Admin/Freight_Charter/neworderfreight";
import Completedfreight from "./Routes/Freight/Admin/Freight_Charter/completedfreight";
import Startedfreight from "./Routes/Freight/Admin/Freight_Charter/startedfreight";
import Freightdash from "./Routes/Freight/Admin/Freight_Dash/freightdash";
import Freightpassword from "./Routes/Freight/Admin/Setting/freightpassword";
import Freightsetting from "./Routes/Freight/Admin/Setting/freightsetting";
import Addtrucks from "./Routes/Freight/Admin/Truck_Freight/addtrucks";
import Freighttruck from "./Routes/Freight/Admin/Truck_Freight/freighttruck";
import Freightbookrequest from "./Routes/Freight/Admin/Freight_Charter/freightbookrequest";
import Adminorder from "./Routes/Freight/Admin/Freight_Charter/adminorder";
import Freightoverview from "./Routes/Freight/Admin/Freight_Charter/freightoverview";
import Truckdetail from "./Routes/Freight/Admin/Truck_Freight/TruckDetail/truckdetail";
import Selectedtruckdetails from "./Routes/Freight/SelectionDetails/selectedtruckdetails";
import Onrepairtruck from "./Routes/Freight/Admin/Freight_Charter/onrepairtruck";



import International from "./Routes/International/international";



import LogistcsNavbar from "./Logistics_manager/Logistics_manager_navbar/Logisticsnavbar";
import Logisticoverview from "./Logistics_manager/Logistic_overview/Logisticoverview";
import Logisticpackagedetails from "./Logistics_manager/Logistics-package-details/Logisticpackagedetails";
import Logisticsactivedispatch from "./Logistics_manager/Logistics_active_dispactch/Logisticsactivedispatch";
import Logisticsdriverrider from "./Logistics_manager/Logisstic_driver_rider/Logisticsdriverrider";
import Logisticcreatecategory from "./Logistics_manager/Logistics_create_category/Logisticcreatecategory";
import Logisticsender from "./Logistics_manager/Logisctisc_create_packages/Logisticsender";
import Logisticsreceiever from "./Logistics_manager/Logisctisc_create_packages/Logisticsreceiever";
import Logisticsitems from "./Logistics_manager/Logisctisc_create_packages/Logisticsitems";
import Logisticssummary from "./Logistics_manager/Logisctisc_create_packages/Logisticssummary";
import Logisticspackages from "./Logistics_manager/Logisticspackages/Logisticspackages";
import Logissticspickups from "./Logistics_manager/Logistics_pickups/Logissticspickups";
import Logisticspicupdetails from "./Logistics_manager/Logisticspickupdetails/Logisticspicupdetails";
import Logisticstrips from "./Logistics_manager/Logisticstrips/Logisticstrips";
import Logisticsinterstatetrips from "./Logistics_manager/Logisticsinterstates/Logisticsinterstatetrips";
import Logisticactivepkg from "./Logistics_manager/Logisticactivepkg/Logisticactivepkg";
import Logissticsacivedps from "./Logistics_manager/Logisticsacivedptc/Logissticsacivedps";
import Logisticsortcenter from "./Logistics_manager/Sorcenterarrival/Logisticsortcenter/Logisticsortcenter";
import Lgisticsettings from "./Logistics_manager/Logistic_settings/Logisticsettings";
import Logisticschnagepass from "./Logistics_manager/Logisticschangepass/Logisticschnagepass";
import Logisticsortassigned from "./Logistics_manager/Sorcenterarrival/Logisticsortassigned";
import LogisticBroadcastMsg from "./Logistics_manager/LogisticBroadcastMessage/LogisticBroadcastMsg";
import Packagereceipt from "./Logistics_manager/Logistics_package_receipt/packagereceipt";
import Logisticundeliveredpackages from "./Logistics_manager/Logistic_Undelivered_Packages/logisticundeliveredpackages";



import Drivernavbar from "./Driver_account/Driver_navbar/Drivernavbar";
import Driverhome from "./Driver_account/Driver_home/Driverhome";
import Driveraccepted from "./Driver_account/Driver_accpted/Driveraccepted";
import Driverassigndetails from "./Driver_account/Driver_assigned_details/Driverassigndetails";
import Driverprofile from "./Driver_account/Driver_profile/Driverprofile";
import Driverresetpass from "./Driver_account/Driver_pass/Driverresetpass";


import Ridernavbar from "./Rider_account/Rider_navbar/Ridernavbar";
import Riderhome from "./Rider_account/RIder_home/Riderhome";
import Rideraccepted from "./Rider_account/Rider_accpted/Rideraccepted";
import Riderassigneddetails from "./Rider_account/Rider_assigned_details/Riderassigneddetails";
import Riderprofile from "./Rider_account/Rider_profile/Riderprofile";
import Riderresetpass from "./Rider_account/Rider_reset_pass/Riderresetpass";



import MerchantAdminNav from "./Routes/Marketplace/Merchant/MerchantDash/MerchantAdminNav";
import MerchantOverview from "./Routes/Marketplace/Merchant/MerchantOverview/MerchantOverview";
import MerchantProducts from "./Routes/Marketplace/Merchant/MerchantProduct/MerchantProducts";
import MerchantOrder from "./Routes/Marketplace/Merchant/MercahntOrder/MerchantOrder";
import MerchantAccount from "./Routes/Marketplace/Merchant/MerchantAccount/MerchantAccount";
import AddProduct from "./Routes/Marketplace/Merchant/MerchantProduct/AddProduct";
import MerchantProDetail from "./Routes/Marketplace/Merchant/MerchantProduct/MerchantProDetail";
import Supermarket from "./Routes/Marketplace/ProductCategory/supermarket";
import Appliance from "./Routes/Marketplace/ProductCategory/appliance";
import Sporting from "./Routes/Marketplace/ProductCategory/sportinggoods";
import Health from "./Routes/Marketplace/ProductCategory/healthbeauty";
import Computing from "./Routes/Marketplace/ProductCategory/computing";
import DrinkAndGrocery from "./Routes/Marketplace/ProductCategory/grocery";
import Electronics from "./Routes/Marketplace/ProductCategory/electronics";
import BabyProduct from "./Routes/Marketplace/ProductCategory/baby,kidstoy";
import Fashion from "./Routes/Marketplace/ProductCategory/fashion";
import Phoneandtablet from "./Routes/Marketplace/ProductCategory/phonestablets";
import Kitchen from "./Routes/Marketplace/ProductCategory/kitchenutensils";
import HomeOffice from "./Routes/Marketplace/ProductCategory/homeOffice";
import Gaming from "./Routes/Marketplace/ProductCategory/gaming";
import Buildingmaterials from "./Routes/Marketplace/ProductCategory/buildingmaterial";
import AllProductDetail from "./Routes/Marketplace/AllProductDetail/productdeatils";
import EmptyCart from "./Routes/Marketplace/Cart/EmptyCart";
import CartListView from "./Routes/Marketplace/Cart/CartListView";
import AllTodaydealproduct from "./Routes/Marketplace/SpecialProduct/AllSpecialProduct/alltodaydealproduct"
import AllTopsellingproduct from "./Routes/Marketplace/SpecialProduct/AllSpecialProduct/alltopsellingproduct";
import Recommendedproducts from "./Routes/Marketplace/SpecialProduct/AllSpecialProduct/recommendedproducts";
import Alltrendingproducts from "./Routes/Marketplace/SpecialProduct/AllSpecialProduct/alltrendingproducts";
import Trackyourorder from "./Routes/Tracking/trackyourorder";





import Marketnavbar from "./marketplace_admin/market_navbar/marketnavbar";
import Marketoverview from "./marketplace_admin/marketoverview/Marketoverview";
import Marketplacestore from "./marketplace_admin/marketplace_store/Marketplacestore";
import Marketmdetails from "./marketplace_admin/markplace_merchantsdetails/Marketmdetails";
import Marketplacecategory from "./marketplace_admin/marketplace_category/Marketplacecategory";
import Marketplacechnagepassword from "./marketplace_admin/mareplace_admin_changepass/marketplaceadminpass";
import MarketplaceSettings from "./marketplace_admin/Markeplace_admin_setting/marketplacesettingss";
import MarkeplaceadminMessage from "./marketplace_admin/mareplace_admin_msg/markeplaceadminmsg";
import MarketplaceAdminorder from "./marketplace_admin/Marketplaceadminorder/markplaceadminorder";
import MarketplaceadminBanner from "./marketplace_admin/Marplace_admin_banner/marketplaceadminbanner";
import MarketplaceCustomer from "./marketplace_admin/markplace_admin_customer/markeplacecustomer";
import MarketplaceProducts from "./marketplace_admin/marketplace_products/markeplaceproductss";
import Storeproducts from "./marketplace_admin/marketplace_admin_store_products/storeproducts";
import Returnpolicy from "./Routes/Return_Policy/returnpolicy";


import Usernavbar from "./Routes/Marketplace/MarketUser/usernavbar";
import UserProfile from "./Routes/Marketplace/MarketUser/UserProfile";
import Customerorder from "./Routes/Marketplace/MarketUser/customerorder";
import Customermsginbox from "./Routes/Marketplace/MarketUser/customermsginbox";
import Customersaveditem from "./Routes/Marketplace/MarketUser/customersaveditem";
import Accountmanagement from "./Routes/Marketplace/MarketUser/accountmanagement";
import Addressbook from "./Routes/Marketplace/MarketUser/addressbook";
import Recentview from "./Routes/Marketplace/MarketUser/recentview";
import Pendingreview from "./Routes/Marketplace/MarketUser/pendingreview";


import BusinessNavbar from "./Business_account/Navbar/business-navbar";
import  HomeBusiness from "./Business_account/Business-home/home";






function App() {
  const {isLoggedIn, user , token, businessRoles} = useContext(AuthContext)
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0,0)
  },[location])

 

  

  const isMarketplacePage = location.pathname === '/Market';
  const isMerchantForm = location.pathname === '/MerchantForm';
  const isSupermarket = location.pathname === '/supermarket';
  const isHealth = location.pathname === '/healthbeauty';
  const isHomeOffice = location.pathname === '/homeOffice';
  const isAppliance  = location.pathname === '/appliance';
  const isPhoneandTablet = location.pathname === '/phonestablets';
  const isComputing = location.pathname === '/computing';
  const isElectronics = location.pathname === '/electronics';
  const isFashion = location.pathname === '/fashion';
  const isBabyproduct = location.pathname === '/baby,kidstoy';
  const isGaming = location.pathname === '/gaming';
  const isSporting = location.pathname === '/sportinggoods';
  const isDrinking = location.pathname === '/grocery';
  const isKitchen = location.pathname === '/kitchenutensils'
  const isAllProductDetail = location.pathname.startsWith('/product');
  const iscartEmpty = location.pathname === '/EmptyCart'
  const isCartList = location.pathname === '/CartListView'
  const isAllTodayDeal = location.pathname === '/alltodaydealproduct'
  const isTopSelling = location.pathname === "/alltopsellingproduct"
  const isRecommended = location.pathname === '/recommendedproduct'
  const isTrending = location.pathname === '/alltrendingproducts'
  const isShippingDetail = location.pathname === '/ShippingDetail'
  const isUserProfile = location.pathname === '/UserProfile'
  const isCustomerOrder = location.pathname === '/customerorder'
  const isCustomerMsgInbox = location.pathname === '/customermsginbox'
  const isCustomerSaveItem = location.pathname === '/customersaveditem'
  const isAccountManagement = location.pathname === '/accountmanagement'
  const isPendingReview = location.pathname === '/pendingreview'
  const isRecentReview = location.pathname === '/recentview'
  const isAddressBook = location.pathname ===  '/addressbook'
  const isRetunPolicy = location.pathname === '/returnpolicy'
  const isTrackYourOrder = location.pathname === '/trackyourorder'
  const isBuilding = location.pathname === '/buildingmaterial'




  if (!isLoggedIn){
    return (
      <Fragment>
        <Routes>
          <Route path="/" element={!isMarketplacePage && !isMerchantForm  && !isSupermarket && !isHealth && !isHomeOffice && !isAppliance && !isPhoneandTablet && !isComputing && !isElectronics && !isFashion && !isBabyproduct && !isGaming && !isSporting && !isDrinking && !isKitchen && !isAllProductDetail && !iscartEmpty && !isCartList && !isRecommended && !isAllTodayDeal  && !isTopSelling &&  !isTrending &&  !isShippingDetail && !isUserProfile && !isCustomerOrder && !isCustomerMsgInbox && !isCustomerSaveItem && !isAccountManagement && !isAddressBook && !isPendingReview && !isRecentReview  && !isRetunPolicy && !isTrackYourOrder && !isBuilding && <Navbar/>}>
            <Route index element={<Home/>}/>
            <Route path="/About" element={<About/>}/>
            <Route path="*" element={<NotFound/>}/>
            <Route path="/Contact" element={<Contact/>}/>
            <Route path="/Blog" element={<Blog/>}/>
            <Route path="/FaqComponent" element={<FaqComponent/>}/>
            <Route path="/Sender" element={<Sender/>}/>
            <Route path="/SignUp" element={<Signup/>}/>
            <Route path="/Login" element={<Login/>}/>
            <Route path="/ForgetPass" element={<ForgetPass/>}/>
            <Route path="/ResetPassword" element={<ResetPassword/>}/>
            <Route path="/PackageReceiver" element={<PackageReceiver/>}/>
            <Route path="/PackageCategory" element={<PackageCategory/>}/>
            <Route path="/ShippingDetail" element={<ShippingDetail/>}/>
            <Route path="/Getquote" element={<Getquote/>}/>
            <Route path="/summary" element={<Summary/>}/>
            <Route path="/blog/:blogId" element={<SingleBlog />} />
            <Route path="/EmailVerificationForm" element={<EmailVerificationForm />} />
            <Route path="/OtpRequest" element={<OtpRequest />} />
            <Route path="/onetimeverificationcode" element={<Onetimeverificationcode />} />
            <Route path="/Market" element={<Market/>}/>
            <Route path="/MerchantForm" element={<MerchantForm/>}/>
            <Route path="/EmptyCart" element={<EmptyCart/>}/>
            <Route path="/CartListView" element={<CartListView/>}/>
            <Route path="/supermarket" element={<Supermarket/>}/>
            <Route path="/healthbeauty" element={<Health/>}/>
            <Route path="/homeOffice" element={<HomeOffice/>}/>
            <Route path="/appliance" element={<Appliance/>}/>
            <Route path="/phonestablets" element={<Phoneandtablet/>}/>
            <Route path="/computing" element={<Computing/>}/>
            <Route path="/electronics" element={<Electronics/>}/>
            <Route path="/fashion" element={<Fashion/>}/>
            <Route path="/baby,kidstoy" element={<BabyProduct/>}/>
            <Route path="/gaming" element={<Gaming/>}/>
            <Route path="/sportinggoods" element={<Sporting/>}/>
            <Route path="/grocery" element={<DrinkAndGrocery/>}/>
            <Route path="/kitchenutensils" element={<Kitchen/>}/>
            <Route path="/buildingmaterial" element={<Buildingmaterials/>}/>
            <Route path="/product/:id" element={<AllProductDetail/>}/>
            <Route path="/alltodaydealproduct" element={<AllTodaydealproduct/>}/>
            <Route path="/alltopsellingproduct" element={<AllTopsellingproduct/>}/>
            <Route path="/recommendedProduct" element={<Recommendedproducts/>}/>
            <Route path="/alltrendingproducts" element={<Alltrendingproducts/>}/>
            <Route path="/charteringservice" element={<Charteringservice/>}/>
            <Route path="/carcharterservice" element={<Carcharterservice/>}/>
            <Route path="/jeepcharterservice" element={<Jeepcharterservice/>}/>
            <Route path="/siennacharterservice" element={<Siennacharterservice/>}/>
            <Route path="/buscharterservice" element={<Buscharterservice/>}/>
            <Route path="/executivecharter" element={<Executivecharter/>}/>
            <Route path="/car-details/:id" element={<Charterdetail />} />
            <Route path="/passengerdetails" element={<Passengerdetails/>}/>
            <Route path="/truckselection" element={<Truckselection/>}/>
            <Route path="/international" element={<International/>}/>
            <Route path="/UserProfile" element={<UserProfile />} />
            <Route path="/customerorder" element={<Customerorder />} />
            <Route path="/usernavbar" element={<Usernavbar />}/>
            <Route path="/customermsginbox" element={<Customermsginbox />}/>
            <Route path="/customersaveditem" element={<Customersaveditem />}/>
            <Route path="/accountmanagement" element={<Accountmanagement />}/>
            <Route path="/recentview" element={<Recentview />}/>
            <Route path="/pendingreview" element={<Pendingreview />}/>
            <Route path="/addressbook" element={<Addressbook />}/>
            <Route path="/returnpolicy" element={<Returnpolicy />}/>
            <Route path="/suscriberform" element={<SuscriberForm />}/>
            <Route path="/trackyourorder" element={<Trackyourorder />}/>
            <Route path="/selectedtruck/:id" element={<Selectedtruckdetails />}/>
            <Route path="/businessdetails" element={<Businessdetails />}/>
          </Route>
        </Routes>
        <Footer/>
        <ScrollToTop/>
      </Fragment>
    ); } 



    if (token && user?.role === 'support'){
      
      return(
        <Routes>
        <Route path="/" element={<SupportDash />}>
          <Route index element={<OverView />} />
          <Route path="/SupportMessage" element={<SupportMessage />} />
          <Route path="/AdminChat" element={<AdminChat />} />
          <Route path="/TrackPackage" element={<TrackPackage />} />
          <Route path="/SupportSetting" element={<SupportSetting />} />
          <Route path="/SupportChangePass" element={<SupportChangePass />} />
        </Route>
      </Routes>
    )
  }
  
  if(token && user?.role === 'customer'){
    return(
      <Fragment>
      <Routes>
        <Route path="/Market" element={<Market/>}/>
            <Route path="/MerchantForm" element={<MerchantForm/>}/>
            <Route path="/EmptyCart" element={<EmptyCart/>}/>
            <Route path="/CartListView" element={<CartListView/>}/>
            <Route path="/ShippingDetail" element={<ShippingDetail/>}/>
            <Route path="/UserProfile" element={<UserProfile/>}/>
            <Route path="/CustomerChangePass" element={<CustomerChangePass/>}/>
            <Route path="/supermarket" element={<Supermarket/>}/>
            <Route path="/healthbeauty" element={<Health/>}/>
            <Route path="/gaming" element={<Gaming/>}/>
            <Route path="/phonestablets" element={<Phoneandtablet/>}/>
            <Route path="/kitchenutensils" element={<Kitchen/>}/>
            <Route path="/homeOffice" element={<HomeOffice/>}/>
            <Route path="/fashion" element={<Fashion/>}/>
            <Route path="/baby,kidstoy" element={<BabyProduct/>}/>
            <Route path="/electronics" element={<Electronics/>}/>
            <Route path="/grocery" element={<DrinkAndGrocery/>}/>
            <Route path="/computing" element={<Computing/>}/>
            <Route path="/appliance" element={<Appliance/>}/>
            <Route path="/buildingmaterial" element={<Buildingmaterials/>}/>
            <Route path="/sportinggoods" element={<Sporting/>}/>
            <Route path="/product/:id" element={<AllProductDetail/>}/>
            <Route path="/EmptyCart" element={<EmptyCart/>}/>
            <Route path="/CartListView" element={<CartListView/>}/>
            <Route path="/alltodaydealproduct" element={<AllTodaydealproduct/>}/>
            <Route path="/alltopsellingproduct" element={<AllTopsellingproduct/>}/>
            <Route path="/recommendedProduct" element={<Recommendedproducts/>}/>
            <Route path="/alltrendingproducts" element={<Alltrendingproducts/>}/>
            <Route path="/ShippingDetail" element={<ShippingDetail/>}/>
            <Route path="/UserProfile" element={<UserProfile />} />
            <Route path="/customerorder" element={<Customerorder />} />
            <Route path="/customermsginbox" element={<Customermsginbox />}/>
            <Route path="/customersaveditem" element={<Customersaveditem />}/>
            <Route path="/accountmanagement" element={<Accountmanagement />}/>
            <Route path="/recentview" element={<Recentview />}/>
            <Route path="/pendingreview" element={<Pendingreview />}/>
            <Route path="/addressbook" element={<Addressbook />}/>
            <Route path="/returnpolicy" element={<Returnpolicy />}/>
            <Route path="/trackyourorder" element={<Trackyourorder />}/>
      </Routes>
      <Footer/>
      </Fragment>
    )
  } 


  if (token && user?.role === "superadmin") {
    return (
      <Routes>
        <Route path="/" element={<SuperNavbar/>}>
          <Route index element={<Superadminoverview/>}/>
          <Route path="/Superadmindispatches"element={<Superadmindispatches/>}/>
          <Route path="/allusers"element={<AllUsers/>}/>
          <Route path="/packages/:id" element={<Superadminpackagedeatails/>}/>
          <Route path="/dispatchdetails/:id" element={<SuperAdminDispatchDetails/>}/>
          <Route path="/Superadminlogistic" element={<Superadminlogistic/>}/>
          <Route path="/Superadminresgisterlogistics" element={<Superadminresgisterlogistics/>}/>
          <Route path="/Superamdindriver" element={<Superamdindriver />} />
          <Route path="/Superadminregisterdriver" element={<Superadminregisterdriver/>}/>
          <Route path="/Superadminrider" element={<Superadminrider/>}/>
          <Route path="/Superadminregisterrider" element={<Superadminregisterrider/>}/>
          <Route path="/Superadminmrarketmanager" element={<Superadminmrarketmanager/>}/>
          <Route path="/Superregisstermarkeplace" element={<Superregisstermarkeplace/>}/>
          <Route path="/Superadminsupport" element={<Superadminsupport/>}/>
          <Route path="/Supperadminregistersupport" element={<Supperadminregistersupport/>}/>
          <Route path="/Superadminblog" element={<Superadminblog/>}/>
          <Route path="/Superadmincreateblog" element={<Superadmincreateblog/>}/>
          <Route path="/Superadminchat" element={<Superadminchat/>}/>
          <Route path="/Superadminbroadcast" element={<Superadminbroadcast/>}/>
          <Route path="/Superadminsetting" element={<Superadminsetting/>}/>
          <Route path="/Superadminchangepass" element={<Superadminchangepass/>}/>
          <Route path="/superadmintoken" element={<SuperadminToken/>}/>
          <Route path="/superadmincharterer" element={<SuperadminCharterer/>}/>
          <Route path="/superadminfreight" element={<SuperAdminFreight/>}/>
          <Route path="/createchartererofficer" element={<Createchartererofficer/>}/>
          <Route path="/createfreightofficer" element={<Createfreightofficer/>}/>
          <Route path="/superadminviewallpackage" element={<Superadminviewallpackage/>}/>
          <Route path="/superadminshippingcost" element={<Superadminshippingcost/>}/>
          <Route path="/superadmingetquote" element={<Superadmingetquote/>}/>
          <Route path="/superadmincreateshipcost" element={<Superadmincreateshipcost/>}/>
          <Route path="/superadmininvestor" element={<Superadmininvestor/>}/>
          <Route path="/superadmincreateinvestor" element={<Superadmincreateinvestor/>}/>
          <Route path="/ontripdriver" element={<Ontripdriver/>}/>
          <Route path="/localshippingcost" element={<Localshippingcost/>}/>
          <Route path="/createlocalshippingcost" element={<Createlocalshippingcost/>}/>
          <Route path="/superadminundeliveredpackages" element={<Superadminundeliveredpackages/>}/>
          <Route path="/superadminallbranchpackages" element={<Superadminallbranchpackages/>}/>
          <Route path="/superadmingetquotelocalcost" element={<Superadmingetquotelocalcost/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Route>
      </Routes>
    );
  }

  else if(token && user?.role === "logisticsmanager"){
    return(
      <Routes>
        <Route path="/" element={<LogistcsNavbar/>}>
          <Route  index element={<Logisticoverview/>}/>
          <Route  path="/details/:id" element={<Logisticpackagedetails/>}/>
          <Route  path="/Logisticsactivedispatch" element={<Logisticsactivedispatch/>}/>
          <Route  path="/Logisticsdriverrider" element={<Logisticsdriverrider/>}/>
          <Route  path="/Logisticcreatecategory" element={<Logisticcreatecategory/>}/>
          <Route  path="/Logisticsender" element={<Logisticsender/>}/>
          <Route  path="/Logisticsreceiever" element={<Logisticsreceiever/>}/>
          <Route  path="/Logisticsitems" element={<Logisticsitems/>}/>
          <Route  path="/Logisticssummary" element={<Logisticssummary/>}/>
          <Route  path="/Logisticspackages" element={<Logisticspackages/>}/>
          <Route  path="/Logissticspickups" element={<Logissticspickups/>}/>
          <Route  path="/predetails/:id" element={<Logisticspicupdetails/>}/>
          <Route  path="/Logisticstrips" element={<Logisticstrips/>}/>
          <Route  path="/Logisticsinterstatetrips" element={<Logisticsinterstatetrips/>}/>
          <Route  path="/Logisticactivepkg" element={<Logisticactivepkg/>}/>
          <Route  path="/Logissticsacivedps" element={<Logissticsacivedps/>}/>
          <Route  path="/Logisticsortcenter" element={<Logisticsortcenter/>}/>
          <Route  path="/Lgisticsettings" element={< Lgisticsettings/>}/>
          <Route  path="/Logisticschnagepass" element={<Logisticschnagepass/>}/>
          <Route  path="/Logisticsortassigned" element={<Logisticsortassigned/>}/>
          <Route  path="/LogisticBroadcastMsg" element={<LogisticBroadcastMsg/>}/>
          <Route  path="/packagereceipt" element={<Packagereceipt/>}/>
          <Route  path="/logisticundeliveredpackages" element={<Logisticundeliveredpackages/>}/>
        </Route>
      </Routes>
    )
  }

  else if(token && user?.role === "driver"){
    return(
      <Routes>
        <Route path="/" element={<Drivernavbar/>}>
          <Route index element={<Driverhome/>}/>
          <Route path="/Driveraccepted" element={<Driveraccepted/>}/>
          <Route path="/details/:assignmentId" element={<Driverassigndetails />} />
          <Route path="/Driveraccepted/details/:assignmentId" element={<Driverassigndetails />} />
          <Route path="/Driverprofile" element={<Driverprofile/>} />
          <Route path="/Driverresetpass" element={<Driverresetpass/>} />
        </Route>
      </Routes>
    )
  }

  else if(token && user?.role === "rider"){
    return(
      <Routes>
        <Route path="/" element={<Ridernavbar/>}>
          <Route index element={<Riderhome/>}/>
          <Route path="/Rideraccepted" element={<Rideraccepted/>}/>
          <Route path="/details/:assignmentId" element={<Riderassigneddetails/>}/>
          <Route path="/Rideraccepted/details/:assignmentId" element={<Riderassigneddetails/>}/>
          <Route path="/Riderprofile" element={<Riderprofile/>}/>
          <Route path="/Riderresetpass" element={<Riderresetpass/>}/>
        </Route>
      </Routes>
    )
  }  

  else if(token && user?.role === "marketplacemanager"){
    return(
      <Routes>
        <Route path="/" element={<Marketnavbar/>}>
          <Route index element={<Marketoverview/>}/>
          <Route path="/Marketplacestore" element={<Marketplacestore/>}/>
          <Route path="/user/:id" element={<Marketmdetails/>}/>
          <Route path="/Marketplacecategory" element={<Marketplacecategory/>}/>
          <Route path="/marketplaceadminpass" element={<Marketplacechnagepassword/>}/>
          <Route path="/marketplacesettingss" element={<MarketplaceSettings/>}/>
          <Route path="/markeplaceadminmsg" element={<MarkeplaceadminMessage/>}/>
          <Route path="/markplaceadminorder" element={<MarketplaceAdminorder/>}/>
          <Route path="/marketplaceadminbanner" element={<MarketplaceadminBanner/>}/>
          <Route path="/markeplacecustomer" element={<MarketplaceCustomer/>}/>
          <Route path="/markeplaceproductss" element={<MarketplaceProducts/>}/>
          <Route path="/storeproducts/:merchantId" element={<Storeproducts />} />

        </Route>
      </Routes>
    )
  }


  else if (token && user?.role === "merchant") {
    return(
      <Routes>
      <Route path="/" element={<MerchantAdminNav/>}>
        <Route index element={<MerchantOverview/>}/>
        <Route path="/MerchantOrder" element={<MerchantOrder/>}/>
        <Route path="/MerchantProducts" element={<MerchantProducts/>}/>
        <Route path="/MerchantAccount" element={<MerchantAccount/>}/>
        <Route path="/AddProduct" element={<AddProduct/>}/>
        <Route path="/product/:id" element={<MerchantProDetail />} /> 
      </Route>
    </Routes>
    )
  }
  else if (token && user?.role === "charterer") {
    return(
      <Routes>
      <Route path="/" element={<Charterdash/>}>
      <Route index element={<Overview/>}/>
      <Route path="/vehicle" element={<Vehicle/>}/>
      <Route path="/addvehicle" element={<Addvehicle/>}/>
      <Route path="/setting" element={<Setting/>}/>
      <Route path="/charterpassword" element={<Charterpassword/>}/>
      <Route path="/completedcharter" element={<Completedcharter/>}/>
      <Route path="/startedcharter" element={<Startedcharter/>}/>
      <Route path="/bookrequest" element={<Bookrequest/>}/>
      <Route path="/order" element={<Order/>}/>
      <Route path="/charter" element={<Charters/>}/>
      <Route path="/vehicle/:id" element={<Vehicledetail />} /> 
      </Route>
    </Routes>
    )
  }
  else if (token && user?.role === "freightmanager") {
    return(
      <Routes>
      <Route path="/" element={<Freightdash/>}>
      <Route index element={<Freightoverview/>}/>
      <Route path="/startedfreight" element={<Startedfreight/>}/>
      <Route path="/completedfreight" element={<Completedfreight/>}/>
      <Route path="/freightpassword" element={<Freightpassword/>}/>
      <Route path="/freightsetting" element={<Freightsetting/>}/>
      <Route path="/addtrucks" element={<Addtrucks/>}/>
      <Route path="/freighttruck" element={<Freighttruck/>}/>
      <Route path="/neworderfreight" element={<Neworderfreight/>}/>
      <Route path="/adminorder" element={<Adminorder/>}/>
      <Route path="/freightbookrequest" element={<Freightbookrequest/>}/>
      <Route path="/onrepairtruck" element={<Onrepairtruck/>}/>
      <Route path="/truck/:id" element={<Truckdetail />} /> 
      </Route>
    </Routes>
    )
  }

  else if (token) {
    const isBusinessIndustry = Array.isArray(businessRoles?.businesses) 
      ? businessRoles.businesses.some(
          (business) => business.industry === user?.role
        ) 
      : false;

    if (isBusinessIndustry) {
      return (
        <Routes>
          <Route path="/" element={<BusinessNavbar />}>
            <Route index element={<HomeBusiness />} />
          </Route>
        </Routes>
      );
    }
}

  

}

export default App;
